import { apiSlice } from "../../app/api/apiSlice";
import { parseParamsToQueryString } from "../../utils/functions";

export const projectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectList: builder.query({
      query: ({ resource, params }) => ({
        url: `/${resource}`,
        method: "GET",
        params: parseParamsToQueryString(params),
      }),
    }),
    getProject: builder.query({
      query: ({ id }) => ({
        url: `project/single/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetProjectListQuery, useGetProjectQuery } = projectApiSlice;
