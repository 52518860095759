import { apiSlice } from "../../app/api/apiSlice";

export const adminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getList: builder.mutation({
      query: ({ resource, params }) => ({
        url: `/admin/list/${resource}`,
        method: "GET",
        params,
      }),
    }),
    getOne: builder.mutation({
      query: ({ resource, id }) => ({
        url: `/admin/one/${resource}/${id}`,
        method: "GET",
      }),
    }),
    getMany: builder.mutation({
      query: ({ resource, params }) => ({
        url: `/admin/many/${resource}`,
        method: "GET",
        params,
      }),
    }),
    update: builder.mutation({
      query: ({ resource, id, data }) => ({
        url: `/admin/${resource}/${id}`,
        method: "POST",
        body: data,
      }),
    }),
    create: builder.mutation({
      query: ({ resource, data }) => ({
        url: `/admin/${resource}`,
        method: "PUT",
        body: data,
      }),
    }),
    delete: builder.mutation({
      query: ({ resource, id }) => ({
        url: `/admin/one/${resource}/${id}`,
        method: "DELETE",
      }),
    }),

    deleteMany: builder.mutation({
      query: ({ resource, params }) => ({
        url: `/admin/many/${resource}`,
        method: "DELETE",
        params,
      }),
    }),
    getReference: builder.mutation({
      query: ({ resource, params }) => ({
        url: `admin/reference/${resource}`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetListMutation,
  useGetOneMutation,
  useGetManyMutation,
  useUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
  useDeleteManyMutation,
  useGetReferenceMutation,
} = adminApiSlice;
