import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { debug } from "../../utils/logger";

const EmailVerificationPage = () => {
  const { t } = useTranslation();
  const userId = localStorage.getItem("__uId");
  debug(userId);

  const resendMessage = () => {
    //todo
  };
  return (
    <div
      className="gradient-container parent-flex-column"
      style={{ backgroundImage: "url(/images/gradient-background.png)" }}
    >
      <div className="frame">
        <div className="top">
          <div className="text-wrapper">Weryfikacja adresu e-mail</div>
          <div className="info">
            <p>
              Aby aktywować konto musimy zweryfikować, że podany adres e-mail
              należy do Ciebie.
            </p>
          </div>
          <p className="text-wrapper">
            Wiadomość z linkiem pozwalającym dokonać weryfikacji została przed
            chwilą wysłana na podany przez Ciebie adres e-mail.
          </p>
          <p className="text-wrapper">
            <span>
              Sprawdź swoją skrzynkę pocztową. Jeżeli nie widzisz wiadomości od
              nas, sprawdź folder SPAM, nie mamy kontroli nad tym, czy Twój
              dostawca e-mail nie umieści jej właśnie tam.
              <br />
              <br />
            </span>
            <Link onClick={resendMessage()} className="text-wrapper">
              Kliknij tutaj(disabled now)
            </Link>
            <span>
              {" "}
              jeżeli po upływie 3 minut nadal nie możesz znaleźć wiadomości od
              nas, wyślemy ją ponownie.
              <br />
              <br />
              Czy zweryfikowałeś adres e-mail?
            </span>
          </p>
          <Link to="/login">
            <button className="btn btn-primary">{t("Login")}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
