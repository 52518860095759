import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Loading from "../../prototypes/Loading";
import { useGetProjectQuery } from "../../../features/project/projectApiSlice";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ProjectStage } from "../../prototypes/ProjectStage";
import ProjectMembersAside from "./ProjectMembersAside";
import { selectCurrentUser } from "../../../features/auth/authSlice";
import "./style.scss";
import ProjectHeading from "./ProjectHeading";
import ProjectVacats from "./ProjectVacats";

const SingleProjectsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const elementRef = useRef(null);
  const user = useSelector(selectCurrentUser);
  const scrollToElement = () => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { data: project, isLoading } = useGetProjectQuery({ id });

  if (isLoading) {
    return (
      <div className="ptba4 plra2">
        <Loading />
      </div>
    );
  }

  if (!project?.data) {
    return <div className="ptba4 plra2">{t("No-items-to-display")}</div>;
  }
  return (
    <>
      <div className={`${user ? "aside-left " : ""}ptba4 plra2 project-page`}>
        <Link to={"/projects"} className="go-back-link">
          <ArrowBackIosIcon />
          {t("List-of-projects")}
        </Link>
        <ProjectHeading
          title={project.data?.title}
          status={project.data?.status}
          imageName={project.data?.avatarUrl}
          startTime={project.data?.startTime}
          endTime={project.data?.endTime}
          tags={project.data?.tags}
          cta={project.data?.cta}
          scrollToElement={scrollToElement}
        />
        <div className="description">
          <p>{project.data?.description}</p>
        </div>
        <div className="container">
          <div className="box shadow-box">
            <div className="text-container">
              <h3>{project.data?.solutionHeader}</h3>
              <p>{project.data?.solutionDescription}</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="box">
            <div className="text-container">
              <h3>{t("The-prototype-included-such-features-as")}:</h3>
              <div className="frame">
                <div className="ellipse" />
                <img
                  className="img"
                  alt="Vector"
                  src="/images/vector-1-5-black.svg"
                />
              </div>
              <ul className="dotted-list">
                {project.data?.prototypeFunctions.map((func, index) => (
                  <li key={index}>{func}</li>
                ))}
              </ul>
            </div>
            <img
              className="rectangle"
              alt="Rectangle"
              src="/images/stones/stone-blue.png"
            />
          </div>
        </div>
        <div className="container" ref={elementRef} id="vacats">
          <h3>{t("Who-we-looking-for")}</h3>
          <ProjectVacats vacats={project.data?.vacats} />
        </div>
        <div className="container">
          <h3>{t("Project-stage")}</h3>
          <ProjectStage stage={project.data?.stage} />
        </div>
      </div>
      {user && project.data?.projectMembers && project.data?.ownerId && (
        <ProjectMembersAside
          ownerId={project.data.ownerId}
          projectMembers={project.data.projectMembers}
        />
      )}
    </>
  );
};

export default SingleProjectsPage;
