import {
  BooleanInput,
  DateTimeInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  required,
  SimpleFormIterator,
  ArrayInput,
  useEditController,
  useRefresh,
} from "react-admin";
import { statusChoices, stageChoices } from "../../../../utils/consts";
import {
  useGetImageUrlMutation,
  useUploadResourceAvatarMutation,
} from "../../../../features/file/fileApiSlice";
import AvatarUpload from "../../../prototypes/AvatarUpload/AvatarUpload";

export const ProjectEdit = (props) => {
  const refresh = useRefresh();
  const [getAvatar] = useGetImageUrlMutation();
  const [saveAvatar] = useUploadResourceAvatarMutation();
  const { record, save } = useEditController(props);
  return (
    <Edit>
      <AvatarUpload
        imageName={record?.avatarUrl}
        id={record?.id}
        refetch={refresh}
        uploadAvatar={saveAvatar}
        getAvatar={getAvatar}
        resource={"admin/image/projects"}
        style={{ marginTop: "10px", marginLeft: "10px" }}
      />
      <SimpleForm>
        <TextInput source="avatarUrl" fullWidth disabled />
        <TextInput source="id" disabled />
        <TextInput source="title" validate={[required()]} />
        <TextInput
          source="description"
          multiline
          fullWidth
          validate={[required()]}
        />

        <TextInput source="cta" multiline fullWidth validate={[required()]} />
        <TextInput
          source="solutionDescription"
          multiline
          fullWidth
          validate={[required()]}
        />
        <TextInput source="solutionHeader" fullWidth validate={[required()]} />
        <ArrayInput source="prototypeFunctions">
          <SimpleFormIterator>
            <TextInput />
          </SimpleFormIterator>
        </ArrayInput>

        <ReferenceInput source="ownerId" reference="users">
          <SelectInput optionText="userName" validate={[required()]} />
        </ReferenceInput>

        <ReferenceInput source="areaId" reference="areas">
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <h6>Tags</h6>
        <ReferenceArrayInput source="tags" reference="skills">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <h6>Project Members</h6>
        <ReferenceArrayInput source="projectMembers" reference="users">
          <SelectArrayInput optionText="userName" />
        </ReferenceArrayInput>
        <DateTimeInput source="startTime" validate={[required()]} />
        <DateTimeInput source="endTime" validate={[required()]} />
        <SelectInput
          source="status"
          validate={[required()]}
          defaultValue={0}
          choices={statusChoices}
        />
        <SelectInput
          source="stage"
          validate={[required()]}
          defaultValue={0}
          choices={stageChoices}
        />
        <BooleanInput source="published" />
        <BooleanInput source="public" />
        <DateTimeInput source="createdAt" disabled />
        <DateTimeInput source="updatedAt" disabled />
      </SimpleForm>
    </Edit>
  );
};
