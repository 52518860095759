import { AreaShow } from "./AreaShow";
import { AreaList } from "./AreaList";
import { AreaEdit } from "./AreaEdit";
//import { AreaCreate } from "./AreaCreate";

const Areas = {
  list: AreaList,
  show: AreaShow,
  edit: AreaEdit,
  //create: AreaCreate,
};
export default Areas;
