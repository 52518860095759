import { ProjectShow } from "./ProjectShow";
import { ProjectList } from "./ProjectList";
import { ProjectEdit } from "./ProjectEdit";
import { ProjectCreate } from "./ProjectCreate";

const Projects = {
  list: ProjectList,
  show: ProjectShow,
  edit: ProjectEdit,
  create: ProjectCreate,
};

export default Projects;
