import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  EmailField,
  ReferenceField,
} from "react-admin";

export const StakeholderShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phone" />
      <ReferenceField source="ownerId" reference="users">
        <TextField source="userName" />
      </ReferenceField>
      <ReferenceField source="areaId" reference="areas">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="companyId" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="links" />
      <TextField source="description" />
      <NumberField source="satisfied" />
      <NumberField source="engaged" />
      <NumberField source="monitored" />
      <NumberField source="informed" />
    </SimpleShowLayout>
  </Show>
);
