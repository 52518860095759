import React from "react";
import Logo from "./Logo";
import Login from "./Login";
import LanguageSelector from "../prototypes/LanguageSelector";
import ProfileIcon from "../sections/ProfileIcon/ProfileIcon";
import {
  selectCurrentUser,
  selectCurrentUserPermissionsLevel,
} from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { TabHeader } from "../prototypes/TabHeader";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);
  const permissionsLevel = useSelector(selectCurrentUserPermissionsLevel);

  return (
    <header className="top-header">
      <Logo />
      <div className="tabs">
        <TabHeader
          className="TAB-header-instance"
          stateProp="default"
          text={t("Projekts")}
          link="/projects"
        />
        <TabHeader
          className="TAB-header-instance"
          stateProp="default"
          text={t("Community")}
          link="/community"
        />
      </div>
      <div style={{ display: "flex" }}>
        <LanguageSelector />
        {!user && <Login />}
        {user ? (
          <ProfileIcon
            property="default"
            user={user}
            permissionsLevel={permissionsLevel}
          />
        ) : null}
      </div>
    </header>
  );
};

export default Header;
