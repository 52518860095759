import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Link className="header-btn login-btn" to={"/login"}>
          {t("Login")}
        </Link>
      </div>
      <div>
        <Link className="header-btn register-btn" to={"/register"}>
          {t("Register")}
        </Link>
      </div>
    </>
  );
};

export default Login;
