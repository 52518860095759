import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./layout/Layout";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import EmailConfirmLandingPage from "./pages/EmailConfirmLandingPage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage";
import NoPrivilegesPage from "./pages/NoPrivilegesPage";
import NotFoundPage from "./pages/NotFoundPage";

import ContactListPage from "./pages/ContactListPage";
import SingleProjectsPage from "./pages/SingleProjectPage/SingleProjectPage";

import RequireAuth from "../features/auth/RequireAuth";

//loggedin user
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import UserPage from "./pages/UserPage/UserPage";

//admin, moderator
import AdminPanelPage from "./pages/admin/AdminPanelPage";
import CommunityPage from "./pages/CommunityPage";
import ProjectsPage from "./pages/ProjectsPage";
import BlockLoginRegister from "../features/auth/BlockLoginRegister";

const ContentSelector = ({ roles }) => (
  <Routes>
    <Route path="/" element={<Layout />}>
      {/* Public */}
      <Route path="/" element={<HomePage />} />
      <Route path="/confirm-email" element={<EmailVerificationPage />} />
      <Route path="/verify-email" element={<EmailConfirmLandingPage />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />
      <Route path="/password-recovery" element={<PasswordRecoveryPage />} />
      <Route path="/contacts" element={<ContactListPage />}></Route>
      <Route path="/community" element={<CommunityPage />}></Route>
      <Route path="/projects" element={<ProjectsPage />}></Route>
      <Route path="/project/:id" element={<SingleProjectsPage />} />

      {/*prevent from logged in users */}
      <Route element={<BlockLoginRegister />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
      </Route>

      {/*Protected routes*/}

      {/*loggedin user*/}
      <Route
        element={
          <RequireAuth
            allowedRoles={[roles.User, roles.Moderator, roles.Admin]}
          />
        }
      >
        <Route path="/dashboard" element={<DashboardPage />}></Route>
        <Route path="/user/:id" element={<UserPage />}></Route>
      </Route>
      {/*Admin, moderator*/}
      <Route
        element={<RequireAuth allowedRoles={[roles.Moderator, roles.Admin]} />}
      >
        <Route path="/admin/*" element={<AdminPanelPage />} />
      </Route>
      {/* Not Found */}
      <Route path="/no-privileges" element={<NoPrivilegesPage />}></Route>
      <Route path="/404" element={<NotFoundPage />}></Route>
      <Route
        path="*"
        element={<Navigate to="/404" state={{ from: "/" }} replace />}
      ></Route>
    </Route>
  </Routes>
);

export default ContentSelector;
