import PropTypes from "prop-types";
import React from "react";
import Badge from "../Badge";
import { Link } from "react-router-dom";
import { faClock, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export const VacatItem = ({
  id,
  title = "Title",
  description = "Desciption...",
  rate = "Nan",
  time = "Nan",
  mode = 0,
  type = "Nan",
  projectId = undefined,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`vacant-item`}>
      <div className="container">
        <div className="line">
          <Badge className="badge-instance" text={type} />
          <div className="title">{title}</div>
        </div>
        <div className="line">
          <div className="desc">
            <p>{description}</p>
          </div>
        </div>
        <div className="line">
          <p>
            <FontAwesomeIcon alt="Sack Dollar icon" icon={faClock} /> {time}
          </p>
          <p>
            <FontAwesomeIcon alt="Sack Dollar icon" icon={faSackDollar} />
            {"  "}
            {rate}
          </p>
        </div>
      </div>
      {id && (
        <Link
          className={"button primary-size disabled-link"}
          to={`/project/join/${projectId}?=vacantId=${id}`}
        >
          {t("Join-poject")}
        </Link>
      )}
    </div>
  );
};

VacatItem.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  rate: PropTypes.string,
  time: PropTypes.string,
  mode: PropTypes.number,
  type: PropTypes.string,
  projectId: PropTypes.number,
};
