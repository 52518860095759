import {
  ChipField,
  DateField,
  List,
  BooleanField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  SortButton,
  CreateButton,
  ExportButton,
  SelectColumnsButton,
  DatagridConfigurable,
  SelectInput,
  FunctionField,
  NullableBooleanInput,
} from "react-admin";
import { statusChoices, stageChoices } from "../../../../utils/consts";

const postFilters = [
  <TextInput label="Title" source="title" alwaysOn />,
  <SelectInput
    label="Status"
    source="status"
    alwaysOn
    emptyText="Unset"
    choices={statusChoices}
  />,
  <SelectInput
    label="Stage"
    source="stage"
    emptyText="Unset"
    alwaysOn
    choices={stageChoices}
  />,
  <NullableBooleanInput source="published" nullLabel="Unset" alwaysOn />,
  <NullableBooleanInput source="public" nullLabel="Unset" alwaysOn />,
];

const ListActions = (props) => (
  <TopToolbar>
    <SelectColumnsButton />
    <SortButton fields={["id", "title", "status", "stage", "startTime"]} />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const getStatusText = ({ status }) => {
  const statusText = statusChoices.find((s) => s.id === status);
  return statusText ? statusText.name : status; //"Unknown";
};

const getStageText = ({ stage }) => {
  const stageText = stageChoices.find((s) => s.id === stage);
  return stageText ? stageText.name : stage; //"Unknown";
};

export const ProjectList = (...props) => {
  return (
    <List {...props} filters={postFilters} actions={<ListActions />}>
      <DatagridConfigurable rowClick="edit">
        <TextField source="id" />
        <TextField source="title" />
        <ReferenceField source="ownerId" reference="users">
          <TextField source="userName" />
        </ReferenceField>
        <ReferenceField source="areaId" reference="areas">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceManyField source="tags" target="tags" reference="skills">
          <SingleFieldList>
            <ChipField source="name" label="Tags" />
          </SingleFieldList>
        </ReferenceManyField>
        <DateField source="startTime" showTime={true} />
        <DateField source="endTime" showTime={true} />
        <FunctionField label="Status" source="status" render={getStatusText} />
        <FunctionField label="Stage" source="stage" render={getStageText} />
        <BooleanField source="published" />
        <BooleanField source="public" />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </DatagridConfigurable>
    </List>
  );
};
