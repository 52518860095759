import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./style.scss";

import { selectCurrentUser } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";

const sectionsData = [
  {
    title: "Art and Science",
    images: [
      "/images/elipse/ellipse-37.svg",
      "/images/elipse/ellipse-36.svg",
      "/images/elipse/ellipse-38.svg",
      "/images/elipse/ellipse-39.svg",
    ],
  },
  {
    title: "Education",
    images: [
      "/images/elipse/ellipse-40.svg",
      "/images/elipse/ellipse-41.svg",
      "/images/elipse/ellipse-42.svg",
      "/images/elipse/ellipse-43.svg",
    ],
  },
  {
    title: "Health",
    images: [
      "/images/elipse/ellipse-44-1.svg",
      "/images/elipse/ellipse-45-1.svg",
      "/images/elipse/ellipse-46-1.svg",
      "/images/elipse/ellipse-47-1.svg",
    ],
  },
  {
    title: "Industry",
    images: [
      "/images/elipse/ellipse-44-1.svg",
      "/images/elipse/ellipse-45-1.svg",
      "/images/elipse/ellipse-46-1.svg",
      "/images/elipse/ellipse-47-1.svg",
    ],
  },
  {
    title: "Smart City",
    images: [
      "/images/elipse/ellipse-40.svg",
      "/images/elipse/ellipse-41.svg",
      "/images/elipse/ellipse-42.svg",
      "/images/elipse/ellipse-43.svg",
    ],
  },
];

export const HomePageCommunityBox = () => {
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);
  return (
    <div className="home-community-box">
      <div
        className="section-background"
        style={{ backgroundImage: "url(/images/community-background.png)" }}
      >
        <img
          className="rectangle"
          alt="Rectangle"
          src="/images/community-overflow-rectangle.png"
        />
      </div>

      <div className="community-container">
        <div className="title">SPOŁECZNOŚĆ FUTURE LABS</div>
        <div className="community-blocks">
          {sectionsData.map((section, index) => (
            <div className="community-block" key={index}>
              <div className="title-frame">
                <div className="text-wrapper-3">{section.title}</div>
                <img
                  className="vector-2"
                  alt="Vector"
                  src="/images/arrow-right.png"
                />
              </div>
              <div className="incon-container">
                {section.images.slice(0).map((image, imgIndex) => (
                  <img
                    className="ellipse-2"
                    alt={`Ellipse ${imgIndex}`}
                    src={image}
                    key={imgIndex}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="fl-community-desc">
        <div className="div">
          <p className="future-labs-network">
            <span className="span">
              future labs
              <br />
            </span>
            <span className="text-wrapper-2">network</span>
          </p>
          <div className="frame">
            <div className="ellipse" />
            <img className="img" alt="Vector" src="/images/vector-1-5.svg" />
            <div className="ellipse" />
          </div>
          <p className="nasza-platforma">
            Nasza platforma umożliwia łatwe tworzenie zespołów i realizowanie
            projektów, które Cię interesują.
            <br />
            <br />
            {!user
              ? "Zarejestruj się i przeglądaj różne projekty lub zgłoś własne pomysły."
              : null}
          </p>
          {user ? <h1>Welcome {user.userName}!</h1> : null}
        </div>
        <Link to="#learn-more">
          <button className="btn btn-primary learn-more-button">
            <FontAwesomeIcon className="icon" icon={faArrowDown} />
            <div className="text-wrapper">{t("Learn-more")}</div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HomePageCommunityBox;
