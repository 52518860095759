import React from "react";
import { Link } from "react-router-dom";

const Logo = () => (
  <div>
    <Link to="/">
      <img
        className="logo"
        alt="Future Labs"
        src="/images/logo/logo-psnc-futurelabs-building.png"
      />
    </Link>
  </div>
);

export default Logo;
