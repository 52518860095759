import { Create, SimpleForm, TextInput, required } from "react-admin";

export const SkillCreate = () => {
  return (
    <Create title="Create Skill">
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
