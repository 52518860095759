import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import Badge from "./Badge";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Item = ({
  id,
  title = "Title",
  cta = "Desciption...",
  status = 0,
  startTime = "00.00.0000",
  endTime = "00.00.0000",
  area,
  tags,
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-100p">
      <Link to={`/project/${id}`}>
        <div className={`item`}>
          <div className={`rectangle`}>
            <img alt="area-avatar" src={area?.avatarUrl}></img>
          </div>
          <div className="container">
            <div className="title">{title}</div>
            <div className="line">
              <div className="column">
                <div className="text-wrapper">{t("Project-status")}:</div>
                <div className={`status-${status}`}>
                  {status === 2
                    ? t("Completed")
                    : status === 0
                    ? t("Planned")
                    : status === 1
                    ? t("In-progress")
                    : t("Unknown")}
                </div>
              </div>
              <div className="column">
                <div className="text-wrapper-2">{t("Start-date")}:</div>
                <div className="text-wrapper-3">{startTime}r.</div>
              </div>
              <div className="column">
                <div className="text-wrapper-2">{t("End-date")}:</div>
                <div className="text-wrapper-3">{endTime}r.</div>
              </div>
            </div>
            <div className="cta">
              <p>{cta}</p>
            </div>
            <div className="badge-container">
              {tags.map(({ id, skill }) => (
                <Badge key={id} className="badge-instance" text={skill?.name} />
              ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

Item.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  cta: PropTypes.string,
  status: PropTypes.number,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  area: PropTypes.any,
  tags: PropTypes.array,
};
