import { InterestShow } from "./InterestShow";
import { InterestList } from "./InterestList";
import { InterestEdit } from "./InterestEdit";
import { InterestCreate } from "./InterestCreate";

const Interests = {
  list: InterestList,
  show: InterestShow,
  edit: InterestEdit,
  create: InterestCreate,
};

export default Interests;
