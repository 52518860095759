import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faInfoCircle, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import { PASSWORD_REGEX } from "../../utils/consts";

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const userRef = useRef();
  const { handleChangePassword } = useAuth();
  const intialValues = {
    password: "",
    confPassword: "",
  };
  let [isWaiting, setWaiting] = useState(false);
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const id = searchParams.get("id");

  useEffect(() => {
    if (userRef.current) userRef.current.focus();
  }, []);

  useEffect(() => {
    setFormErrors({});
    setIsSubmitting(false);
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.botField.value) {
      // Possibly a bot, reject submission
      alert("Bot detected. Please try again.");
      return;
    }
    setFormErrors(validate(formValues));
    setIsSubmitting(true);
  };

  const validate = (values) => {
    let errors = {};
    if (!values.password || !PASSWORD_REGEX.test(values.password)) {
      errors.password = true;
    }
    if (!values.confPassword) {
      errors.confPassword = "Cannot be blank";
    } else if (values.password !== values.confPassword) {
      errors.confPassword = "Password must match";
    }
    return errors;
  };

  const submitForm = async () => {
    try {
      setWaiting(true);
      await handleChangePassword(formValues.password, id, token);
      setFormValues(intialValues);
    } catch (err) {}
    setWaiting(false);
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }
    setIsSubmitting(false);
  }, [formErrors]);

  return (
    <div
      className="gradient-container parent-flex-column"
      style={{ backgroundImage: "url(/images/gradient-background.png)" }}
    >
      <div className="frame">
        {id && token ? (
          <div className="top">
            <div className="text-wrapper">{t("Forgotten-password")}.</div>
            <div className="info">
              <p>{t("Type-new-password")}.</p>
            </div>
            <form onSubmit={handleSubmit} noValidate>
              <div className="form-row">
                <label htmlFor="password">{t("Password")}</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  ref={userRef}
                  placeholder={t("Password")}
                  value={formValues.password}
                  onChange={handleChange}
                  className={formErrors.password && "error"}
                  required
                />
                {formErrors.password && (
                  <p className={"error instructions"}>
                    <FontAwesomeIcon icon={faInfoCircle} /> 8 to 24 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character.
                    <br />
                    Allowed special characters:{" "}
                    <span aria-label="exclamation mark">!</span>{" "}
                    <span aria-label="at symbol">@</span>{" "}
                    <span aria-label="hashtag">#</span>{" "}
                    <span aria-label="dollar sign">$</span>{" "}
                    <span aria-label="percent">%</span>
                  </p>
                )}
              </div>
              <div className="form-row">
                <label htmlFor="confPassword">
                  {t("Confirm") + " " + t("Password")}
                </label>
                <input
                  type="password"
                  name="confPassword"
                  id="confPassword"
                  placeholder={t("Confirm") + " " + t("Password")}
                  value={formValues.confPassword}
                  onChange={handleChange}
                  className={formErrors.confPassword && "error"}
                  required
                />
                {formErrors.confPassword && (
                  <p className={"error instructions"}>
                    <FontAwesomeIcon icon={faInfoCircle} />{" "}
                    {formErrors.confPassword}
                  </p>
                )}
              </div>
              <div className="form-row">
                <input
                  type="text"
                  name="botField"
                  style={{ display: "none" }}
                  tabIndex="-1"
                  autoComplete="off"
                />
              </div>
              <div className="flex-end">
                <button
                  type="submit"
                  disabled={isWaiting === true ? true : false}
                  className="btn btn-primary"
                >
                  {t("Submit")}
                </button>
              </div>
            </form>
            <Link to={"/"} className="go-back-link">
              <FontAwesomeIcon icon={faHome} />
              {t("Home")}
            </Link>
          </div>
        ) : (
          <div className="top">
            <div className="text-wrapper">{t("Forgotten-password")}.</div>
            <div className="info">
              <p>{t("Invalid-y") + " " + t("Token")}.</p>
            </div>
            <Link to={"/"} className="go-back-link">
              <FontAwesomeIcon icon={faHome} />
              {t("Home")}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangePasswordPage;
