import React, { useState } from "react";
import i18n from "../../i18n/index.js";
import Select, { components } from "react-select";

const { Option } = components;
const IconOption = (props) => (
  <Option {...props} className="selecting-lang">
    <img
      className="lang-flag"
      src={"/" + props.data.icon}
      style={{ marginBottom: "-2px" }}
      alt={props.data.label}
    />
  </Option>
);

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

  const languages = [
    {
      value: "gb",
      label: "English",
      icon: "images/lang-flags/Flag_of_gb.png",
    },
    {
      value: "pl",
      label: "Polish",
      icon: "images/lang-flags/Flag_of_pl.png",
    },
  ];

  const chooseLanguage = (e) => {
    //e.preventDefault();
    i18n.changeLanguage(e.value); // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
    setSelectedLanguage(e.value);
  };

  return (
    <Select
      className="lang-dropdown"
      defaultValue={languages.find((lang) => lang.value === selectedLanguage)}
      options={languages}
      components={{
        Option: IconOption,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      onChange={chooseLanguage}
      isSearchable={false}
      getOptionLabel={(e) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            className="lang-flag"
            src={"/" + e.icon}
            style={{ paddingLeft: "2px" }}
            alt={e.label}
          />
        </div>
      )}
    />
  );
};

export default LanguageSelector;
