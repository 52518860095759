import { StakeholderShow } from "./StakeholderShow";
import { StakeholderList } from "./StakeholderList";
import { StakeholderEdit } from "./StakeholderEdit";
import { StakeholderCreate } from "./StakeholderCreate";

const Companies = {
  list: StakeholderList,
  show: StakeholderShow,
  edit: StakeholderEdit,
  create: StakeholderCreate,
};

export default Companies;
