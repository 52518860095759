import React, { createContext, useState, useEffect, useCallback } from "react";
import {
  setCredentials,
  setStatus,
  setLogout,
} from "../features/auth/authSlice";
import {
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useChangePasswordMutation,
  useForgotPasswordRequestMutation,
} from "../features/auth/authApiSlice";
import { useGetUserWithGroupsMutation } from "../features/user/userApiSlice";
import { useDispatch } from "react-redux";
import { successNotification } from "../utils/popNotifications";
import Cookies from "js-cookie";
import { selectCurrentToken } from "../features/auth/authSlice";
import { useSelector } from "react-redux";
import { endpointErrorHandler } from "../utils/endpointErrorHandler";
import { COOKIE_DOMAIN } from "../utils/consts";
const AuthContext = createContext({});

let isWaiting = true;

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();
  const [logout] = useLogoutMutation();
  const [getUserWithGroups, { isLoading, isSuccess, isError }] =
    useGetUserWithGroupsMutation();
  const [changePassword] = useChangePasswordMutation();
  const [forgotPasswordRequest] = useForgotPasswordRequestMutation();
  const activeToken =
    Cookies.get("activeToken") === "true" ||
    Cookies.get("activeToken") === undefined;
  const accessToken = useSelector(selectCurrentToken);

  const getUserData = useCallback(() => {
    getUserWithGroups()
      .unwrap()
      .then((data) => {
        dispatch(setCredentials({ user: data, accessToken }));
      })
      .catch((error) => {
        dispatch(setLogout());
        Cookies.set("activeToken", false, {
          httpOnly: false,
          samesite: "Strict",
          domain: COOKIE_DOMAIN,
        });
        localStorage.removeItem("__uId");
      });
  }, [getUserWithGroups, dispatch]);

  useEffect(() => {
    if (activeToken && accessToken) {
      getUserData();
    } else {
      isWaiting = false;
    }
  }, [activeToken, getUserData /*accessToken*/]);

  useEffect(() => {
    dispatch(setStatus({ isLoading, isSuccess, isError, isWaiting }));
  }, [dispatch, isLoading, isError, isSuccess]);

  const handleLogin = async (username, password) => {
    try {
      const userData = await login({ username, password }).unwrap();
      dispatch(setCredentials({ ...userData }));
      localStorage.setItem("__uId", userData?.user?.id);
    } catch (err) {
      endpointErrorHandler(err);
      throw err;
    }
  };

  const handleRegister = async (
    firstName,
    lastName,
    username,
    email,
    password
  ) => {
    try {
      const userId = await register({
        firstName,
        lastName,
        username,
        email,
        password,
      }).unwrap();
      localStorage.setItem("__uId", userId);
      successNotification(
        "Success",
        "Register Completed. Check your email to activate account."
      );
    } catch (err) {
      endpointErrorHandler(err);
      throw err;
    }
  };

  const handleLogout = async () => {
    try {
      Cookies.set("activeToken", false, {
        httpOnly: false,
        sameSite: "Strict",
        domain: COOKIE_DOMAIN,
      });
      localStorage.removeItem("__uId");
      await logout();
    } catch (err) {}
    dispatch(setLogout());
  };

  const handleForgotPasswordRequest = async (email) => {
    try {
      await forgotPasswordRequest({ email }).unwrap();
      successNotification(
        "Success",
        "Check your email to view next reset password steps."
      );
    } catch (err) {
      endpointErrorHandler(err);
      throw err;
    }
  };

  const handleChangePassword = async (password, id, token) => {
    try {
      await changePassword({ token, id, password }).unwrap();
      successNotification(
        "Success",
        "Your password has been successfully changed."
      );
    } catch (err) {
      endpointErrorHandler(err);
      throw err;
    }
  };

  const authContextValue = {
    handleLogin,
    handleRegister,
    handleLogout,
    handleForgotPasswordRequest,
    handleChangePassword,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
