import React from "react";
import { useNavigate } from "react-router-dom";
const NotFoundPage = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <div
      style={{
        border: "unset",
        maxWidth: "400px",
        display: "block",
        margin: "10% auto 10%",
      }}
    >
      <img
        style={{
          border: "unset",
          maxWidth: "400px",
          display: "block",
          margin: "0 0 50px",
          outline: "unset",
          backgroundColor: "unset",
        }}
        src="/images/404-not-found.png"
        alt="notFound"
        name="saveForm"
        className="btTxt submit"
        id="saveForm"
      />
      <div className="flex-v-center parent-flex-column">
        <button onClick={goBack} className="btn btn-primary">
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
