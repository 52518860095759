import PropTypes from "prop-types";
import React, { useReducer, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";
import useAuth from "../../../hooks/useAuth";

const ProfileIcon = ({
  property,
  className,
  divClassName,
  user,
  permissionsLevel,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property: property || "default",
  });
  const { handleLogout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleSignOut = async () => {
    await handleLogout();
    navigate("/", { replace: true });
  };

  const initials = user.firstName
    ? `${user.firstName.charAt(0).toUpperCase()}${user.lastName
        .charAt(0)
        .toUpperCase()}`
    : "XX";
  const profileVariantRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileVariantRef.current &&
        !profileVariantRef.current.contains(event.target)
      ) {
        dispatch("click_outside");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className={`profile-icon property-${state.property} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onClick={() => {
        dispatch("click");
      }}
    >
      {["default", "h-OVER"].includes(state.property) && (
        <div className={`initials ${divClassName}`}>
          <p>{initials}</p>
        </div>
      )}

      {state.property === "variant-3" && (
        <>
          <div className="overlap-group">
            <div className="initials popup">
              <p>{initials}</p>
            </div>
            <div className="ellipse" />
          </div>
          <div ref={profileVariantRef} className="profile-icon-variant">
            <div className="frame">
              <div className="initials-wrapper">
                <div className="initials menu">
                  <p>{initials}</p>
                </div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper text-name">
                  {user.firstName ? user.firstName : " "}{" "}
                  {user.lastName ? user?.lastName : " "}
                </div>
                <div className="text-wrapper text-mail">
                  {user.email ? user.email : " "}
                </div>
              </div>
            </div>
            <div className="divider">
              <img
                className="img"
                alt="Divider"
                src="/images/popup-divider.png"
              />
            </div>
            <div className="links">
              <Link to="/dashboard">
                <button className="button">{t("Dashboard")}</button>
              </Link>
              <Link to="/contacts">
                <button className="button">{t("Contact-list")}</button>
              </Link>
              {permissionsLevel === 10 && (
                <Link to="/admin">
                  <button className="button">{t("Settings")}</button>
                </Link>
              )}
              <div className="divider">
                <img
                  className="img"
                  alt="Divider"
                  src="/images/popup-divider.png"
                />
              </div>
              <button
                className="placeholder-wrapper"
                onClick={() => handleSignOut()}
              >
                <div className="sign-out">{t("Log-out")}</div>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function reducer(state, action) {
  if (state.property === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          property: "h-OVER",
        };
    }
  }

  if (state.property === "h-OVER") {
    switch (action) {
      case "mouse_leave":
        return {
          property: "default",
        };

      case "click":
        return {
          property: "variant-3",
        };
    }
  }

  if (state.property === "variant-3") {
    switch (action) {
      case "click":
        return {
          property: "default",
        };
    }
  }

  if (state.property === "variant-3") {
    switch (action) {
      // existing cases...
      case "click_outside":
        return {
          property: "default",
        };
    }
  }

  return state;
}

ProfileIcon.propTypes = {
  property: PropTypes.oneOf(["variant-3", "h-OVER", "default"]),
  text: PropTypes.string,
};

export default ProfileIcon;
