import {
  Create,
  SimpleForm,
  NumberInput,
  TextInput,
  required,
} from "react-admin";

export const GroupCreate = () => {
  return (
    <Create title="Create Group">
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <NumberInput
          source="permissionLevel"
          validate={[required()]}
          min={0}
          max={99}
          defaultValue={0}
        />
      </SimpleForm>
    </Create>
  );
};
