import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetProjectListQuery } from "../../features/project/projectApiSlice";
import Loading from "../prototypes/Loading";
import { Item } from "../prototypes/ProjectItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SEARCH_INPUT_REGEX } from "../../utils/consts";

const ProjectsPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [field, setField] = useState("id");
  const [selectedOption, setSelectedOption] = useState("id-ASC");
  const [order, setOrder] = useState("ASC");
  const [inputValue, setInputValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const params = {
    pagination: { page, perPage },
    sort: { field, order },
    filter: filterValue ? { title: filterValue } : {},
  };
  const resource = "project/list";
  const {
    data: projects,
    isLoading,
    isFetching,
    isError,
  } = useGetProjectListQuery({
    resource,
    params,
  });

  useEffect(() => {
    if (!isLoading && projects) {
      setTotalPages(Math.ceil(projects.total / perPage));
    }
  }, [isLoading, projects, perPage]);

  if (isLoading) {
    return (
      <div className="ptba4 plra2">
        <Loading />
      </div>
    );
  }

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const goToNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (SEARCH_INPUT_REGEX.test(value)) {
      setInputValue(value);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (inputValue !== "") {
      setFilterValue(inputValue);
    }
  };

  const handleSelectChange = (e) => {
    switch (e.target.value) {
      case "id-ASC":
        setField("id");
        setOrder("ASC");
        break;
      case "id-DESC":
        setField("id");
        setOrder("DESC");
        break;
      case "startTime-ASC":
        setField("startTime");
        setOrder("ASC");
        break;
      case "startTime-DESC":
        setField("startTime");
        setOrder("DESC");
        break;
      default:
        break;
    }
    setSelectedOption(e.target.value);
  };
  const handlePerPageChange = (e) => {
    switch (e.target.value) {
      case "5":
        setPerPage(5);
        break;
      case "10":
        setPerPage(10);
        break;
      case "25":
        setPerPage(25);
        break;
      case "50":
        setPerPage(50);
        break;
      default:
        break;
    }
    setPerPage(e.target.value);
  };

  return (
    <div className="ptba4 plra2 projects-page w1000px parent-flex-row">
      <h1 className="text-center">{t("List-of-projects")}</h1>
      <div className="project-list-head">
        <p>
          {t("Number-of-projects")}: {projects?.total}
        </p>
        <div style={{ display: "inline-flex", gap: "10px" }}>
          <div>
            <p>{t("Per-page")}:</p>
            <select value={perPage} onChange={handlePerPageChange}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
          <div>
            <p>{t("Sort-by")}:</p>
            <select value={selectedOption} onChange={handleSelectChange}>
              <option value="id-ASC">{t("ID-ASC")}</option>
              <option value="id-DESC">{t("ID-DESC")}</option>
              <option value="startTime-ASC">{t("StartTime-ASC")}</option>
              <option value="startTime-DESC">{t("StartTime-DESC")}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="input-wrapper">
        <input
          type="text"
          name="title"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={t("Search") + "..."}
        />
        <IconButton aria-label="search" onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </div>
      <div className="list-container">
        {isFetching && !isError && <Loading />}
        {!isFetching && projects?.data.length === 0 && (
          <div className="ptba4 plra2">
            {t("No-projects-with-phrase")} "{inputValue}"
          </div>
        )}
        {!isFetching && !projects?.data && (
          <div className="ptba4 plra2">{t("No-items-to-display")}</div>
        )}
        {!isFetching &&
          !isError &&
          projects.data.map(
            ({ id, title, cta, status, startTime, endTime, area, tags }) => (
              <Item
                key={id}
                id={id}
                title={title}
                cta={cta}
                status={status}
                startTime={
                  startTime
                    ? new Date(startTime).toLocaleDateString("pl-PL")
                    : undefined
                }
                endTime={
                  endTime
                    ? new Date(endTime).toLocaleDateString("pl-PL")
                    : undefined
                }
                area={area}
                tags={tags}
              />
            )
          )}
      </div>

      <div className="navigation-container">
        <button
          className="navigation-button"
          onClick={goToPreviousPage}
          disabled={isFetching || page === 1}
        >
          <ArrowBackIcon />
        </button>
        <button
          className="navigation-button"
          onClick={goToNextPage}
          disabled={isFetching || page === totalPages}
        >
          <ArrowForwardIcon />
        </button>
        <span>{`${page} / ${totalPages}`}</span>
      </div>
    </div>
  );
};

export default ProjectsPage;
