import { CompanyShow } from "./CompanyShow";
import { CompanyList } from "./CompanyList";
import { CompanyEdit } from "./CompanyEdit";
import { CompanyCreate } from "./CompanyCreate";

const Companies = {
  list: CompanyList,
  show: CompanyShow,
  edit: CompanyEdit,
  create: CompanyCreate,
};

export default Companies;
