import { apiSlice } from "../../app/api/apiSlice";

export const fileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getImageUrl: builder.mutation({
      query: ({ imageName, resource }) => ({
        url: `${resource}/${imageName}`,
        method: "GET",
        responseHandler: (response) =>
          response.blob().then((blob) => URL.createObjectURL(blob)),
      }),
    }),
    uploadResourceAvatar: builder.mutation({
      query: ({ formData, resource, id }) => ({
        url: `${resource}/${id}`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const { useGetImageUrlMutation, useUploadResourceAvatarMutation } =
  fileApiSlice;
