import { UserShow } from "./UserShow";
import { UserList } from "./UserList";
import { UserEdit } from "./UserEdit";
import { UserCreate } from "./UserCreate";

const Users = {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
};

export default Users;
