import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from "react-admin";

export const CompanyCreate = () => {
  return (
    <Create title="Create Company">
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <NumberInput
          min={0}
          max={99}
          defaultValue={0}
          source="type"
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
