import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  DateField,
  UrlField,
  ArrayField,
  EditButton,
} from "react-admin";

export const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="userName" defaultValue="Empty" />
      <TextField source="email" defaultValue="Empty" />
      <TextField source="firstName" defaultValue="Empty" />
      <TextField source="lastName" defaultValue="Empty" />
      <TextField source="phone" defaultValue="Empty" />
      <TextField source="avatarUrl" defaultValue="Empty" />
      <DateField source="emailVerified" defaultValue="Empty" showTime={true} />
      <DateField source="lastLoginAt" defaultValue="Empty" showTime={true} />
      <DateField source="createdAt" defaultValue="Empty" showTime={true} />
      <DateField source="updatedAt" defaultValue="Empty" showTime={true} />
      <TextField source="activationKey" defaultValue="Empty" />
      <TextField source="projectCount" defaultValue="Empty" />
      <TextField source="parentSubject" defaultValue="Empty" />
      <TextField source="selfPresentation" defaultValue="Empty" />

      {/* Displaying User's Skills */}

      <ReferenceArrayField
        label="User Skills"
        reference="user-skills"
        target="userId"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      {/* Displaying User's Interests */}
      {/*
      <ReferenceArrayField source="interests" reference="user/interests">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      */}
      {/* Displaying User's Group Memberships */}
      <ReferenceManyField
        label="User Groups"
        reference="groups"
        target="userId"
      >
        <SingleFieldList>
          <ChipField source="group.name" />
        </SingleFieldList>
      </ReferenceManyField>

      {/* Displaying Projects where the User is an Owner */}
      {/*
      <ReferenceManyField reference="user/projects" target="ownerId">
        <Datagrid>
          <TextField source="title" />
          <DateField source="startTime" />
          <DateField source="endTime" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      */}
      {/* Displaying Vacats where the User is an Applicant */}
      {/*
      <ReferenceManyField reference="user/applicants" target="userId">
        <Datagrid>
          <ReferenceField source="vacantId" reference="user/vacants">
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      */}
    </SimpleShowLayout>
  </Show>
);
