import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export const ProjectStage = ({ stage = 0 }) => {
  const { t } = useTranslation();

  const stageRefs = {
    stage0: useRef(null),
    stage1: useRef(null),
    stage2: useRef(null),
    stage3: useRef(null),
    stage4: useRef(null),
    stage5: useRef(null),
    stage6: useRef(null),
    stage7: useRef(null),
    stage8: useRef(null),
  };

  const focusOnStage = (stageRef) => {
    if (stageRef && stageRef.current) {
      stageRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "nearest",
      });
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }
  };

  useEffect(() => {
    switch (stage) {
      case 0:
        focusOnStage(stageRefs.stage0);
        break;
      case 1:
        focusOnStage(stageRefs.stage1);
        break;
      case 2:
        focusOnStage(stageRefs.stage2);
        break;
      case 3:
        focusOnStage(stageRefs.stage2);
        break;
      case 4:
        focusOnStage(stageRefs.stage0);
        break;
      case 5:
        focusOnStage(stageRefs.stage1);
        break;
      case 6:
        focusOnStage(stageRefs.stage2);
        break;
      case 7:
        focusOnStage(stageRefs.stage2);
        break;
      case 8:
        focusOnStage(stageRefs.stage8);
        break;
      default:
        break;
    }
  }, [stage]);

  const stages = Array.from({ length: 9 }, (_, index) => ({
    dotChecked: index <= stage,
    lineChecked: index < stage,
  }));
  const renderDotsAndLines = () => {
    return stages.map((s, index) => {
      return (
        <React.Fragment key={index}>
          <div className={`dot ${s.dotChecked ? "checked" : ""}`}></div>
          {index < stages.length - 1 && (
            <div className={`line ${s.lineChecked ? "checked" : ""}`}></div>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="timeline">
      <div className="container">
        <div className="lines">{renderDotsAndLines()}</div>

        <div className="cards">
          <div className="card" ref={stageRefs.stage0}>
            <p>{t("Research")}</p>
          </div>
          <div className="card mid" ref={stageRefs.stage1}>
            <p>{t("Problem-definition")}</p>
          </div>
          <div className="card mid" ref={stageRefs.stage2}>
            <p>{t("Idea-generation")}</p>
          </div>
          <div className="card mid" ref={stageRefs.stage3}>
            <p>{t("Prototype")}</p>
          </div>
          <div className="card mid" ref={stageRefs.stage4}>
            <p>{t("Testing")}</p>
          </div>
          <div className="card mid" ref={stageRefs.stage5}>
            <p>{t("Proposal-preparation")}</p>
          </div>
          <div className="card mid" ref={stageRefs.stage6}>
            <p>{t("Business-model")}</p>
          </div>
          <div className="card mid" ref={stageRefs.stage7}>
            <p>{t("Marketing")}</p>
          </div>
          <div className="card" ref={stageRefs.stage8}>
            <p>{t("Product-sales")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectStage.propTypes = {
  stage: PropTypes.number,
};
