import React, { useReducer, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

export const TabHeader = ({
  stateProp,
  className,
  text = "Placeholder",
  link,
}) => {
  const location = useLocation();
  const isActive = location.pathname === link;

  return (
    <Link to={link}>
      <div
        className={`tabs-header ${isActive ? `${className} active` : className}`}
      >
        <div className="placeholder">{text}</div>
      </div>
    </Link>
  );
};
