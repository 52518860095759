import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./style.scss";
import Badge from "../Badge";
import { Link } from "react-router-dom";
import { useGetImageUrlMutation } from "../../../features/file/fileApiSlice";
import ImageWithCallback from "../ImageWithCallback";

const EntityItem = ({
  id,
  firstName = "Name",
  lastName = "Surname",
  avatarUrl = "",
  skills = undefined,
  selfPresentation = "",
}) => {
  const { t } = useTranslation();
  const [getAvatar] = useGetImageUrlMutation();

  return (
    <div className="entity">
      <div className="avatar-line">
        <div className="person-icon">
          <ImageWithCallback
            imageName={avatarUrl}
            className="avatar-icon"
            alt=""
            getAvatar={getAvatar}
            resource={"user/image"}
          />
        </div>
        <div className="avatar-text">
          <div className="text-wrapper-name">
            {firstName} {lastName}
          </div>
          <div className="text-wrapper-desc">
            {skills.length > 0 &&
              skills.map(({ skill }) => (
                <Badge
                  key={skill?.id}
                  className="badge-instance"
                  text={skill?.name}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="content">
        <p className="text-wrapper component-3">{selfPresentation}</p>
        <Link to={`/user/${id}`}>
          <button className="button">
            <div className="placeholder">{t("Learn-more")}</div>
          </button>
        </Link>
      </div>
    </div>
  );
};

EntityItem.propTypes = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatarUrl: PropTypes.string,
  skills: PropTypes.array,
  selfPresentation: PropTypes.string,
};

export default EntityItem;
