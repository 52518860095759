import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faInfoCircle, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import { EMAIL_REGEX } from "../../utils/consts";

const PasswordRecoveryPage = () => {
  const { t } = useTranslation();
  const userRef = useRef();
  const { handleForgotPasswordRequest } = useAuth();
  const intialValues = {
    email: "",
  };
  let [isWaiting, setWaiting] = useState(false);
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (userRef.current) userRef.current.focus();
  }, []);

  useEffect(() => {
    setFormErrors({});
    setIsSubmitting(false);
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.botField.value) {
      // Possibly a bot, reject submission
      alert("Bot detected. Please try again.");
      return;
    }
    setFormErrors(validate(formValues));
    setIsSubmitting(true);
  };

  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Cannot be blank";
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = "Invalid email format";
    }
    return errors;
  };

  const submitForm = async () => {
    try {
      setWaiting(true);
      await handleForgotPasswordRequest(formValues.email);
      setFormValues(intialValues);
    } catch (err) {}
    setWaiting(false);
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }
    setIsSubmitting(false);
  }, [formErrors]);

  return (
    <div
      className="gradient-container parent-flex-column"
      style={{ backgroundImage: "url(/images/gradient-background.png)" }}
    >
      <div className="frame">
        <div className="top">
          <div className="text-wrapper">{t("Forgotten-password")}.</div>
          <div className="info">
            <p>{t("Type-your-email-address-to-reset-password")}.</p>
          </div>
          <form onSubmit={handleSubmit} noValidate>
            <div className="form-row">
              <label htmlFor="email">{t("Email")}</label>
              <input
                type="text"
                name="email"
                id="email"
                ref={userRef}
                value={formValues.email}
                onChange={handleChange}
                className={formErrors.email && "error"}
                required
              />
              {formErrors.email && (
                <p className={"error instructions"}>
                  <FontAwesomeIcon icon={faInfoCircle} /> {formErrors.email}
                </p>
              )}
            </div>
            <div className="form-row">
              <input
                type="text"
                name="botField"
                style={{ display: "none" }}
                tabIndex="-1"
                autoComplete="off"
              />
            </div>
            <div className="flex-end">
              <button
                type="submit"
                disabled={isWaiting === true ? true : false}
                className="btn btn-primary"
              >
                {t("Send-reset-password-link")}
              </button>
            </div>
          </form>
          <Link to={"/"} className="go-back-link">
            <FontAwesomeIcon icon={faHome} />
            {t("Home")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecoveryPage;
