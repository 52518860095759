import {
  USERNAME_REGEX,
  PASSWORD_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
} from "./consts";

export const validateRegistration = (values) => {
  let errors = {};

  if (!values.username) {
    errors.username = { key: "Cannot-be-blank" };
  } else if (!USERNAME_REGEX.test(values.username)) {
    errors.username = {
      key: "Field-must-be-more-than-and-less-than-characters",
      keys: { max: 24, min: 4 },
    };
  }
  if (!values.email) {
    errors.email = "Cannot-be-blank";
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Invalid-format";
  }
  if (!values.firstName) {
    errors.firstName = { key: "Cannot-be-blank" };
  } else if (values.firstName.length < 3) {
    errors.firstName = {
      key: "Field-must-be-more-characters-than",
      keys: { val: 3 },
    };
  } else if (values.firstName.length > 24) {
    errors.firstName = {
      key: "Field-must-be-less-characters-than",
      keys: { val: 24 },
    };
  }
  if (!values.lastName) {
    errors.lastName = { key: "Cannot-be-blank" };
  } else if (values.lastName.length < 3) {
    errors.lastName = {
      key: "Field-must-be-more-characters-than",
      keys: { val: 3 },
    };
  } else if (values.lastName.length > 24) {
    errors.lastName = {
      key: "Field-must-be-less-characters-than",
      keys: { val: 24 },
    };
  }
  if (!values.password || !PASSWORD_REGEX.test(values.password)) {
    errors.password = true;
  }
  if (!values.confPassword) {
    errors.confPassword = "Cannot-be-blank";
  } else if (values.password !== values.confPassword) {
    errors.confPassword = "Password-must-match";
  }
  return errors;
};

export const validateLogin = (values) => {
  let errors = {};
  if (!values.username) {
    errors.username = { key: "Cannot-be-blank" };
  } else if (!USERNAME_REGEX.test(values.username)) {
    errors.password = "Invalid-username-or-password";
  }
  if (!values.password) {
    errors.password = { key: "Cannot-be-blank" };
  } else if (!PASSWORD_REGEX.test(values.password)) {
    errors.password = "Invalid-username-or-password";
  }
  return errors;
};

export const validateCahngeUserData = (values, userId) => {
  let errors = {};
  if (!values.id || values.id !== userId) {
    errors.firstName = { key: "Invalid user ID" };
  }
  if (!values.firstName) {
    errors.firstName = { key: "Cannot-be-blank" };
  } else if (values.firstName.length < 3) {
    errors.firstName = {
      key: "Field-must-be-more-characters-than",
      keys: { val: 3 },
    };
  } else if (values.firstName.length > 24) {
    errors.firstName = {
      key: "Field-must-be-less-characters-than",
      keys: { val: 24 },
    };
  }
  if (!values.lastName) {
    errors.lastName = { key: "Cannot-be-blank" };
  } else if (values.lastName.length < 3) {
    errors.lastName = {
      key: "Field-must-be-more-characters-than",
      keys: { val: 3 },
    };
  } else if (values.lastName.length > 24) {
    errors.lastName = {
      key: "Field-must-be-less-characters-than",
      keys: { val: 24 },
    };
  }
  if (!values.userName) {
    errors.userName = { key: "Cannot-be-blank" };
  } else if (!USERNAME_REGEX.test(values.userName)) {
    errors.userName = {
      key: "Field-must-be-more-than-and-less-than-characters",
      keys: { max: 24, min: 4 },
    };
  }
  if (!values.email) {
    errors.email = "Cannot-be-blank";
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Invalid-format";
  }
  if (!values.phone) {
    errors.phone = "Cannot-be-blank";
  } else if (!PHONE_REGEX.test(values.phone)) {
    errors.phone = "Invalid-format";
  }
  if (values.projectCount < 0) {
    errors.projectCount = {
      key: "Field-must-be-more-characters-or-equal",
      keys: { val: 0 },
    };
  }
  if (!values.lastName) {
    errors.lastName = { key: "Cannot-be-blank" };
  } else if (values.lastName.length < 3) {
    errors.lastName = {
      key: "Field-must-be-more-characters-than",
      keys: { val: 3 },
    };
  } else if (values.lastName.length > 24) {
    errors.lastName = {
      key: "Field-must-be-less-characters-than",
      keys: { val: 24 },
    };
  }
  if (!values.parentSubject) {
    errors.parentSubject = { key: "Cannot-be-blank" };
  } else if (values.parentSubject.length < 3) {
    errors.parentSubject = {
      key: "Field-must-be-more-characters-than",
      keys: { val: 3 },
    };
  } else if (values.parentSubject.length > 50) {
    errors.parentSubject = {
      key: "Field-must-be-less-characters-than",
      keys: { val: 50 },
    };
  }
  if (!values.selfPresentation) {
    errors.selfPresentation = { key: "Cannot-be-blank" };
  } else if (values.selfPresentation.length < 3) {
    errors.selfPresentation = {
      key: "Field-must-be-more-characters-than",
      keys: { val: 3 },
    };
  } else if (values.selfPresentation.length > 2500) {
    errors.selfPresentation = {
      key: "Field-must-be-less-characters-than",
      keys: { val: 2500 },
    };
  }
  try {
    values.companyId = parseInt(values.companyId, 10);
  } catch (error) {
    errors.companyId = { key: "Must-be-number" };
  }
  if (values.companyId < 0) {
    errors.companyId = {
      key: "Field-must-be-more-characters-or-equal",
      keys: { val: 0 },
    };
  }
  return errors;
};
