import {
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const CompanyEdit = () => (
  <Edit>
    <SimpleForm>
      <NumberInput source="id" disabled />
      <TextInput source="name" validate={[required()]} />
      <NumberInput
        min={0}
        max={99}
        defaultValue={0}
        source="type"
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);
