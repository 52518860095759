import { useLocation, Navigate, Outlet } from "react-router-dom";
import { selectCurrentStatuses, selectCurrentUser } from "./authSlice";
import { useSelector } from "react-redux";
import Loading from "../../components/prototypes/Loading";
import { debug } from "../../utils/logger";

const BlockLoginRegister = () => {
  const location = useLocation();
  const user = useSelector(selectCurrentUser);

  const { isLoading, isError, isSuccess, isWaiting } = useSelector(
    selectCurrentStatuses
  );

  debug(
    "RequireAuth",
    "isLoading",
    isLoading,
    "isError",
    isError,
    "isSuccess",
    isSuccess,
    "user",
    user
  );

  if (
    (isLoading && !isSuccess) ||
    (!isLoading && !isSuccess && !isError && isWaiting)
  ) {
    return (
      <div className="ptba4">
        <Loading />
      </div>
    );
  }

  return !user ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default BlockLoginRegister;
