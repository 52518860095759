import React, { useState, useEffect } from "react";
import { successNotification } from "../../../utils/popNotifications";
import { useTranslation } from "react-i18next";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../../features/user/userApiSlice";
import Loading from "../../prototypes/Loading";
import { Navigate } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateCahngeUserData } from "../../../utils/validators";
import CompanyDropdown from "./CompanyDropdown";
import AvatarUpload from "../../prototypes/AvatarUpload/AvatarUpload";
import {
  useGetImageUrlMutation,
  useUploadResourceAvatarMutation,
} from "../../../features/file/fileApiSlice";
import { endpointErrorHandler } from "../../../utils/endpointErrorHandler";

const DashboardPage = () => {
  const [updateUser] = useUpdateUserMutation();
  const [isWaiting, setWaiting] = useState(false);
  const intialValues = {
    id: "",
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    projectCount: 0,
    parentSubject: "",
    selfPresentation: "",
    companyId: undefined,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const [uploadAvatar] = useUploadResourceAvatarMutation();
  const [getAvatar] = useGetImageUrlMutation();

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useGetUserQuery();

  useEffect(() => {
    if (isSuccess && user) {
      setFormValues({
        id: user.id || intialValues.user,
        userName: user.userName || intialValues.userName,
        email: user.email || intialValues.email,
        firstName: user.firstName || intialValues.firstName,
        lastName: user.lastName || intialValues.lastName,
        phone: user.phone || intialValues.phone,
        projectCount: user.projectCount || intialValues.projectCount,
        parentSubject: user.parentSubject || intialValues.parentSubject,
        selfPresentation:
          user.selfPresentation || intialValues.selfPresentation,
        companyId: user.companyId || intialValues.companyId,
      });
    }
  }, [isSuccess, user]);

  useEffect(() => {
    setFormErrors({});
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.botField.value) {
      alert("Bot detected. Please try again.");
      return;
    }
    setFormErrors(validateCahngeUserData(formValues, user.id));
    setIsSubmitting(true);
  };

  const submitForm = async () => {
    try {
      setWaiting(true);
      try {
        const { projectCount, ...data } = formValues;
        await updateUser({
          ...data,
        }).unwrap();
        successNotification("Success", "Profile updated");
      } catch (err) {
        setFormErrors({ server: err.status });
        endpointErrorHandler(err);
      }
    } catch (err) {}
    setWaiting(false);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }
    setIsSubmitting(false);
  }, [formErrors]);

  var disabledProps = {};
  if (isWaiting) disabledProps.disabled = true;

  let content;
  if (isLoading) {
    <p>{JSON.stringify(user)}</p>;
    content = <Loading />;
  } else if (isSuccess) {
    content = (
      <div className="ptba4 plra2 def-div w1000px parent-flex-row">
        <h2>{t("Profile-update")}</h2>
        <AvatarUpload
          imageName={user.avatarUrl}
          id={user.id}
          refetch={refetch}
          uploadAvatar={uploadAvatar}
          getAvatar={getAvatar}
          resource={"user/image"}
        />
        <form onSubmit={handleSubmit} noValidate>
          <div className="form-row">
            <label htmlFor="userName">{t("Username")}</label>
            <input
              type="text"
              name="userName"
              id="userName"
              autoComplete="off"
              placeholder={t("Username")}
              value={formValues.userName}
              onChange={handleChange}
              className={formErrors.userName && "error"}
              {...disabledProps}
              required
              disabled
            />
            {formErrors.userName && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t(formErrors.userName?.key, formErrors.userName?.keys)}
              </p>
            )}
          </div>
          <div className="form-row">
            <label htmlFor="email">{t("Email")}</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder={t("Email")}
              value={formValues.email}
              onChange={handleChange}
              className={formErrors.email && "error"}
              {...disabledProps}
              required
              disabled
            />
            {formErrors.email && (
              <p
                className={
                  formErrors.email ? "error instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} /> {t(formErrors.email)}
              </p>
            )}
          </div>
          <div
            className="form-row flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <div style={{ flex: "0 1 49%" }}>
              <label htmlFor="firstName">{t("First-name")}</label>
              <input
                type="test"
                name="firstName"
                id="firstName"
                placeholder={t("First-name")}
                value={formValues.firstName}
                onChange={handleChange}
                className={formErrors.firstName && "error"}
                {...disabledProps}
                required
              />
              {formErrors.firstName && (
                <p className={"error instructions"}>
                  <FontAwesomeIcon icon={faInfoCircle} />{" "}
                  {t(formErrors.firstName?.key, formErrors.firstName?.keys)}
                </p>
              )}
            </div>

            <div style={{ flex: "0 1 49%" }}>
              <label htmlFor="LastName">{t("Last-name")}</label>
              <input
                type="test"
                name="lastName"
                id="lastName"
                placeholder={t("Last-name")}
                value={formValues.lastName}
                onChange={handleChange}
                className={formErrors.lastName && "error"}
                {...disabledProps}
                required
              />
              {formErrors.lastName && (
                <p
                  className={
                    formErrors.lastName ? "error instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />{" "}
                  {t(formErrors.lastName?.key, formErrors.lastName?.keys)}
                </p>
              )}
            </div>
          </div>
          <div className="form-row">
            <label htmlFor="phone">{t("Phone")}</label>
            <input
              type="text"
              name="phone"
              id="phone"
              autoComplete="off"
              placeholder={t("Phone")}
              value={formValues.phone}
              onChange={handleChange}
              className={formErrors.phone && "error"}
              required
              {...disabledProps}
            />
            {formErrors.phone && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} /> {t(formErrors.phone)}
              </p>
            )}
          </div>
          <div className="form-row">
            <label htmlFor="parentSubject">{t("Parent-subject")}</label>
            <input
              type="text"
              name="parentSubject"
              id="parentSubject"
              autoComplete="off"
              placeholder={t("Parent-subject")}
              value={formValues.parentSubject}
              onChange={handleChange}
              className={formErrors.parentSubject && "error"}
              required
              {...disabledProps}
            />
            {formErrors.parentSubject && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t(
                  formErrors.parentSubject?.key,
                  formErrors.parentSubject?.keys
                )}
              </p>
            )}
          </div>
          <div className="form-row flex-column">
            <label htmlFor="selfPresentation">{t("Self-presentation")}</label>
            <textarea
              rows="10"
              type="text"
              name="selfPresentation"
              id="selfPresentation"
              autoComplete="off"
              placeholder={t("Self-presentation")}
              value={formValues.selfPresentation}
              onChange={handleChange}
              className={formErrors.selfPresentation && "error"}
              required
              {...disabledProps}
            />
            {formErrors.selfPresentation && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t(
                  formErrors.selfPresentation?.key,
                  formErrors.selfPresentation.keys
                )}
              </p>
            )}
          </div>
          <div className="form-row">
            <label htmlFor="companyId">{t("Company")}</label>
            <CompanyDropdown
              name={"companyId"}
              formValues={formValues}
              handleChange={handleChange}
              formErrors={formErrors}
              disabledProps={disabledProps}
            />
            {formErrors.companyId && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t(formErrors.companyId?.key, formErrors.companyId?.keys)}
              </p>
            )}
          </div>
          <div className="form-row">
            <label htmlFor="projectCount">{t("Project-count")}</label>
            <input
              type="number"
              name="projectCount"
              id="projectCount"
              autoComplete="off"
              placeholder={t("Project-count")}
              value={formValues.projectCount}
              //onChange={handleChange}
              className={formErrors.projectCount && "error"}
              required
              disabled
            />
            {formErrors.projectCount && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t(formErrors.projectCount?.key, formErrors.projectCount?.keys)}
              </p>
            )}
          </div>
          <div className="form-row">
            <input
              type="text"
              name="botField"
              style={{ display: "none" }}
              tabIndex="-1"
              autoComplete="off"
            />
          </div>
          <div className="flex-end">
            <button
              type="submit"
              disabled={isWaiting === true ? true : false}
              className="btn btn-primary"
            >
              {t("Save")}
            </button>
          </div>
        </form>
      </div>
    );
  } else if (!isLoading && isError) {
    content = <Navigate to="/login" replace />;
  }

  return content;
};

export default DashboardPage;
