import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { faInfoCircle, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateLogin } from "../../utils/validators";

const LoginPage = () => {
  const { t } = useTranslation();
  const userRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { handleLogin } = useAuth();

  const intialValues = { username: "", password: "" };
  let [isWaiting, setWaiting] = useState(false);
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (userRef.current) userRef.current.focus();
  }, []);

  useEffect(() => {
    setFormErrors({});
    setIsSubmitting(false);
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.botField.value) {
      // Possibly a bot, reject submission
      alert("Bot detected. Please try again.");
      return;
    }
    setFormErrors(validateLogin(formValues));
    setIsSubmitting(true);
  };

  const submitForm = async () => {
    try {
      setWaiting(true);
      await handleLogin(formValues.username, formValues.password);
      setFormValues(intialValues);

      navigate(from, { replace: true });
    } catch (err) {
      setFormErrors({ server: err.status });
    }
    setWaiting(false);
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }
    setIsSubmitting(false);
  }, [formErrors]);

  return (
    <div
      className="gradient-container parent-flex-column"
      style={{ backgroundImage: "url(/images/gradient-background.png)" }}
    >
      <div className="def-div w500px">
        <h1 className="ta-c">{t("Login")}</h1>
        <form onSubmit={handleSubmit} noValidate>
          <div className="form-row">
            <label htmlFor="username">{t("Username")}</label>
            <input
              type="text"
              name="username"
              id="username"
              ref={userRef}
              value={formValues.username}
              onChange={handleChange}
              className={
                (formErrors.server ||
                  formErrors.username ||
                  formErrors.password) &&
                "error"
              }
              required
            />
            {formErrors.username && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} /> {t(formErrors.username)}
              </p>
            )}
          </div>
          <div className="form-row">
            <label htmlFor="password">{t("Password")}</label>
            <input
              type="password"
              name="password"
              id="password"
              value={formValues.password}
              onChange={handleChange}
              className={
                (formErrors.server ||
                  formErrors.username ||
                  formErrors.password) &&
                "error"
              }
              required
            />
            {formErrors.password && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} /> {t(formErrors.password)}
              </p>
            )}
          </div>
          <div className="form-row">
            <input
              type="text"
              name="botField"
              style={{ display: "none" }}
              tabIndex="-1"
              autoComplete="off"
            />
          </div>
          <div className="flex-end">
            <Link
              to={"/password-recovery"}
              className="go-back-link"
              style={{ marginRight: "20px" }}
            >
              <FontAwesomeIcon icon={faLock} />
              {t("Forgotten-password")}?
            </Link>
            <button
              type="submit"
              disabled={isWaiting === true ? true : false}
              className="btn btn-primary"
            >
              {t("Login")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default LoginPage;
