import React from "react";
import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  ShowGuesser,
} from "react-admin";
import CustomReduxDataProvider from "./CustomReduxDataProvider";
import { useSelector } from "react-redux";
import "./style.scss";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import BoltIcon from "@mui/icons-material/Bolt";
import SailingIcon from "@mui/icons-material/Sailing";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import users from "./users";
import skills from "./skills";
import interests from "./interests";
import groups from "./groups";
import projects from "./projects";
import areas from "./areas";
import companies from "./companies";
import stakeholders from "./stakeholders";

const AdminPanelPage = () => {
  const apiSlice = useSelector((state) => state.api);
  return (
    <div className="no-parent-flex">
      <Admin
        basename="/admin"
        dataProvider={CustomReduxDataProvider(apiSlice)}
        darkTheme={{ palette: { mode: "dark" } }}
      >
        <Resource
          name="users"
          disableSyncWithLocation
          {...users}
          icon={PersonIcon}
        />
        <Resource
          name="skills"
          disableSyncWithLocation
          {...skills}
          icon={BoltIcon}
        />
        <Resource
          name="interests"
          disableSyncWithLocation
          {...interests}
          icon={SailingIcon}
        />
        <Resource
          name="groups"
          disableSyncWithLocation
          {...groups}
          icon={PeopleIcon}
        />
        <Resource
          name="projects"
          disableSyncWithLocation
          {...projects}
          icon={LibraryBooksIcon}
        />
        <Resource
          name="areas"
          disableSyncWithLocation
          {...areas}
          icon={DocumentScannerIcon}
        />
        <Resource
          name="vacats"
          disableSyncWithLocation
          list={ListGuesser}
          edit={EditGuesser}
          show={ShowGuesser}
          icon={ContactMailIcon}
        />
        <Resource
          name="applicants"
          disableSyncWithLocation
          list={ListGuesser}
          edit={EditGuesser}
          show={ShowGuesser}
          icon={GroupAddIcon}
        />

        <Resource
          name="companies"
          disableSyncWithLocation
          {...companies}
          icon={ApartmentIcon}
        />

        <Resource
          name="stakeholders"
          disableSyncWithLocation
          {...stakeholders}
          icon={Diversity1Icon}
        />
      </Admin>
    </div>
  );
};

export default AdminPanelPage;
