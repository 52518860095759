import {
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  required,
} from "react-admin";

export const StakeholderEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="email" validate={[required()]} />
      <TextInput source="firstName" validate={[required()]} />
      <TextInput source="lastName" validate={[required()]} />
      <TextInput source="phone" validate={[required()]} />
      <ReferenceInput source="ownerId" reference="users">
        <SelectInput optionText="userName" validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput source="areaId" reference="areas">
        <SelectInput optionText="name" validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput source="companyId" reference="companies">
        <SelectInput optionText="name" validate={[required()]} />
      </ReferenceInput>
      <ArrayInput source="links">
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        source="description"
        fullWidth
        multiline
        validate={[required()]}
      />
      <NumberInput
        source="satisfied"
        min={0}
        max={10}
        defaultValue={0}
        validate={[required()]}
      />
      <NumberInput
        min={0}
        max={10}
        defaultValue={0}
        source="engaged"
        validate={[required()]}
      />
      <NumberInput
        min={0}
        max={10}
        defaultValue={0}
        source="monitored"
        validate={[required()]}
      />
      <NumberInput
        min={0}
        max={10}
        defaultValue={0}
        source="informed"
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);
