import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Badge from "../../prototypes/Badge";
import ImageWithCallback from "../../prototypes/ImageWithCallback";
import { useGetImageUrlMutation } from "../../../features/file/fileApiSlice";
import "./style.scss";

const ProjectHeading = ({
  title,
  status,
  imageName,
  startTime,
  endTime,
  tags,
  cta,
  scrollToElement,
}) => {
  const { t } = useTranslation();
  const [getAvatar] = useGetImageUrlMutation();
  return (
    <div className="heading">
      <ImageWithCallback
        imageName={imageName}
        className="rectangle"
        alt="Rectangle"
        getAvatar={getAvatar}
        resource={"project/image"}
      />
      <div className="container">
        <div className="line">
          <h2>{title}</h2>
          <Link
            className={"button primary-size"}
            to={`#vacats`}
            onClick={scrollToElement}
          >
            {t("Join-poject")}
          </Link>
        </div>
        <div className="line">
          <div className="column">
            <div className="text-wrapper">{t("Project-status")}:</div>
            <div className={`status-${status}`}>
              {status === 2
                ? t("Completed")
                : status === 0
                ? t("Planned")
                : status === 1
                ? t("In-progress")
                : t("Unknown")}
            </div>
          </div>
        </div>
        <div className="line">
          <div className="column">
            <div className="text-wrapper-2">{t("Start-date")}:</div>
            <div className="text-wrapper-3">
              {startTime
                ? new Date(startTime).toLocaleDateString("pl-PL") + "r."
                : "NaN"}
            </div>
          </div>
          <div className="column">
            <div className="text-wrapper-2">{t("End-date")}:</div>
            <div className="text-wrapper-3">
              {endTime
                ? new Date(endTime).toLocaleDateString("pl-PL") + "r."
                : "NaN"}
            </div>
          </div>
        </div>
        <div className="badge-container">
          {tags.map(({ id, skill }) => (
            <Badge key={id} className="badge-instance" text={skill?.name} />
          ))}
        </div>
        <div className="cta">
          <p>{cta}</p>
        </div>
      </div>
    </div>
  );
};

ProjectHeading.propTypes = {
  title: PropTypes.string,
  status: PropTypes.number,
  imageName: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  tags: PropTypes.array,
  cta: PropTypes.string,
  scrollToElement: PropTypes.func,
};

export default ProjectHeading;
