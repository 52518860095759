import React from "react";
import ContentSelector from "./components/ContentSelector";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

const ROLES = {
  User: 0,
  Moderator: 5,
  Admin: 10,
};

function App() {
  return (
    <div className="App">
      <ContentSelector roles={ROLES} />
      <ReactNotifications />
    </div>
  );
}

export default App;
