import { GroupShow } from "./GroupShow";
import { GroupList } from "./GroupList";
import { GroupEdit } from "./GroupEdit";
import { GroupCreate } from "./GroupCreate";

const Groups = {
  list: GroupList,
  show: GroupShow,
  edit: GroupEdit,
  create: GroupCreate,
};

export default Groups;
