import React from "react";
import { Link } from "react-router-dom";
const CommunityPage = () => {
  return (
    <div className="ptba4 plra2 parent-flex-row">
      <h1>Strona community - jeszcze trwają prace :)</h1>
    </div>
  );
};

export default CommunityPage;
