import * as React from "react";
import { Show, SimpleShowLayout, TextField, NumberField } from "react-admin";

export const CompanyShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="type" />
    </SimpleShowLayout>
  </Show>
);
