export const URL_BASE = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:8000/";

export const REFRESH_INTERVAL = process.env.REACT_APP_REFRESH_INTERVAL
  ? process.env.REACT_APP_REFRESH_INTERVAL
  : 1000;

export const ADMIN_URL = "admin/";

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN
  ? process.env.REACT_APP_COOKIE_DOMAIN
  : "localhost";

export const POLLING_INTERVAL = {
  pollingInterval: REFRESH_INTERVAL,
};

export const ADMIN_POLLING_INTERVAL = {
  pollingInterval: REFRESH_INTERVAL * 2,
};

export const USERNAME_REGEX = /^[a-zAZ0-9-_]{3,23}/;
export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,23}$/;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
export const SEARCH_INPUT_REGEX = /^[a-zA-Z0-9-]{0,25}$/;
export const PHONE_REGEX =
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

export const statusChoices = [
  { id: 0, name: "Planned" },
  { id: 1, name: "In Progress" },
  { id: 2, name: "Completed" },
];

export const stageChoices = [
  { id: 0, name: "Research" },
  { id: 1, name: "Problem Definition" },
  { id: 2, name: "Idea Generation" },
  { id: 3, name: "Prototype" },
  { id: 4, name: "Testing" },
  { id: 5, name: "Proposal Preparation" },
  { id: 6, name: "Business Model" },
  { id: 7, name: "Marketing" },
  { id: 8, name: "Product Sales" },
];

export const satisfiedChoices = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
];

export const engagedChoices = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
];

export const monitoredChoices = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
];

export const informedChoices = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
];
