import { apiSlice } from "../../app/api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({
        url: "/user/info",
        method: "GET",
      }),
    }),
    getUserWithGroups: builder.mutation({
      query: () => ({
        url: "/user/with-groups",
        method: "GET",
      }),
    }),
    updateUser: builder.mutation({
      query: (userData) => ({
        url: "/user/update",
        method: "POST",
        body: { ...userData },
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserWithGroupsMutation,
  useUpdateUserMutation,
} = userApiSlice;
