import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/signin",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: "/auth/signup",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    confirmEmail: builder.query({
      query: (token) => ({
        url: `/auth/email/verify/${token}`,
        method: "POST",
      }),
    }),
    forgotPasswordRequest: builder.mutation({
      query: (credentials) => ({
        url: "/auth/password/request",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    changePassword: builder.mutation({
      query: (credentials) => ({
        url: "/auth/password/reset",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useConfirmEmailQuery,
  useForgotPasswordRequestMutation,
  useChangePasswordMutation,
} = authApiSlice;
