import PropTypes from "prop-types";
import React from "react";

const Badge = ({ className, text = "Text Here" }) => {
  return (
    <div className={`badge ${className}`}>
      <div className={`badge-base`}>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

export default Badge;

Badge.propTypes = {
  text: PropTypes.string,
};
