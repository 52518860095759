import { Datagrid, List, TextField, ImageField } from "react-admin";

export const AreaList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ImageField source="avatarUrl" title="Icon" />
    </Datagrid>
  </List>
);
