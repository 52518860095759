import { debug } from "./logger";
import { errorNotification } from "./popNotifications";

export function endpointErrorHandler(error) {
  debug("endpointErrorHandler", error);
  if (!error?.status) {
    errorNotification(`Request Failed`, "No Server Response");
  } else if (
    error.status === 400 ||
    error.status === 401 ||
    error.status === 403 ||
    error.status === 404 ||
    error.status === 409 ||
    error.status === 422 ||
    error.status === 429 ||
    error.status === 500
  ) {
    let errorMessage;
    if (Array.isArray(error.data.message)) {
      errorMessage = error.data.message.join(", ");
    } else if (typeof error.data.message === "string") {
      errorMessage = error.data.message;
    } else {
      errorMessage = "Unknown error";
    }
    errorNotification(`Request Failed with code ${error.status}`, errorMessage);
  } else {
    errorNotification(
      `Request Failed with code ${error.status}`,
      "Try again later"
    );
  }
}
