import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

const ImageWithCallback = ({
  imageName,
  getAvatar,
  resource,
  alt = "Image",
  ...props
}) => {
  const [imageUrl, setImageUrl] = useState(null);

  const getUserAvatarUrl = useCallback(
    (imageName, resource) => {
      getAvatar({ imageName, resource })
        .unwrap()
        .then((data) => {
          setImageUrl(data);
        })
        .catch((error) => {});
    },
    [getAvatar]
  );

  useEffect(() => {
    if (imageName) {
      getUserAvatarUrl(imageName, resource);
    }
  }, [getUserAvatarUrl, imageName, resource]);

  return <img alt={alt} {...props} src={imageUrl} />;
};

ImageWithCallback.propTypes = {
  imageName: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  getAvatar: PropTypes.func,
  resource: PropTypes.string,
};

export default ImageWithCallback;
