import React from "react";
import { useFormContext } from "react-hook-form";
import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  required,
  Button,
  useCreate,
} from "react-admin";
import { validateRegistration } from "../../../../utils/validators";
import { generateTempPassword } from "../../../../utils/generators";

const GeneratePasswordButton = () => {
  const { setValue } = useFormContext();

  return (
    <Button
      onClick={() => {
        const newPassword = generateTempPassword();
        setValue("password", newPassword);
        setValue("confPassword", newPassword);
      }}
    >
      <span>Generate Temporary Password</span>
    </Button>
  );
};

export const UserCreate = () => {
  const [create] = useCreate();
  const postSave = (allFields) => {
    const { confPassword, ...data } = allFields;
    create("users", { data });
  };

  return (
    <Create title="Create User" validate={validateRegistration}>
      <SimpleForm onSubmit={postSave}>
        <TextInput source="username" validate={[required()]} />
        <TextInput source="email" validate={[required()]} />
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <PasswordInput source="password" validate={[required()]} />
        <PasswordInput source="confPassword" validate={[required()]} />
        <GeneratePasswordButton />
      </SimpleForm>
    </Create>
  );
};
