import { useGetManyCompaniesQuery } from "../../../features/company/companyApiSlice";
import { useTranslation } from "react-i18next";
const CompanyDropdown = ({
  name,
  formValues,
  handleChange,
  formErrors,
  disabledProps,
}) => {
  const { data, isSuccess, isError } = useGetManyCompaniesQuery();
  const { t } = useTranslation();

  if (isError) {
    return (
      <input
        type="number"
        name={name}
        id={name}
        value={formValues.companyId}
        onChange={handleChange}
        className={formErrors.companyId && "error"}
        required
        {...disabledProps}
      ></input>
    );
  }

  return (
    <select
      name={name}
      id={name}
      value={formValues.companyId}
      onChange={handleChange}
      className={formErrors.companyId && "error"}
      required
      {...disabledProps}
    >
      <option value="0">{t("Unset")}</option>
      {isSuccess && data && (
        <>
          {data.data?.map((e, key) => (
            <option key={key} value={e.id}>
              {e.name}
            </option>
          ))}
        </>
      )}
    </select>
  );
};

export default CompanyDropdown;
