import { SkillShow } from "./SkillShow";
import { SkillList } from "./SkillList";
import { SkillEdit } from "./SkillEdit";
import { SkillCreate } from "./SkillCreate";

const Skills = {
  list: SkillList,
  show: SkillShow,
  edit: SkillEdit,
  create: SkillCreate,
};

export default Skills;
