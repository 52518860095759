import {
  BooleanInput,
  DateTimeInput,
  Create,
  ReferenceInput,
  SimpleForm,
  ArrayField,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  required,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

export const ProjectCreate = () => (
  <Create title="Create Project">
    <SimpleForm>
      <TextInput source="title" validate={[required()]} />
      <TextInput
        source="description"
        multiline
        fullWidth
        validate={[required()]}
      />
      <TextInput source="avatarUrl" multiline />
      <TextInput source="cta" multiline fullWidth validate={[required()]} />
      <TextInput
        source="solutionDescription"
        multiline
        fullWidth
        validate={[required()]}
      />
      <TextInput source="solutionHeader" fullWidth validate={[required()]} />
      <ArrayInput source="prototypeFunctions" validate={[required()]}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <ReferenceInput source="ownerId" reference="users">
        <SelectInput optionText="userName" validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput source="areaId" reference="areas">
        <SelectInput optionText="name" validate={[required()]} />
      </ReferenceInput>
      <h6>Tags</h6>
      <ReferenceArrayInput source="tags" reference="skills">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <DateTimeInput source="startTime" validate={[required()]} />
      <DateTimeInput source="endTime" validate={[required()]} />

      <SelectInput
        source="status"
        validate={[required()]}
        defaultValue={0}
        choices={[
          { id: 0, name: "Planned" },
          { id: 1, name: "In Progress" },
          { id: 2, name: "Completed" },
        ]}
      />
      <SelectInput
        source="stage"
        validate={[required()]}
        defaultValue={0}
        choices={[
          { id: 0, name: "Research" },
          { id: 1, name: "Problem Definition" },
          { id: 2, name: "Idea Generation" },
          { id: 3, name: "Prototype" },
          { id: 4, name: "Testing" },
          { id: 5, name: "Proposal Preparation" },
          { id: 6, name: "Business Model" },
          { id: 7, name: "Marketing" },
          { id: 8, name: "Product Sales" },
        ]}
      />
      <BooleanInput source="published" />
      <BooleanInput source="public" />
    </SimpleForm>
  </Create>
);
