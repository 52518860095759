import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { COOKIE_DOMAIN } from "../utils/consts";
import translationsInEng from "../locales/en/translation.json";
import translationsInPolish from "../locales/pl/translation.json";

// the translations
const resources = {
  gb: {
    translation: translationsInEng,
  },
  pl: {
    translation: translationsInPolish,
  },
};

const options = {
  // order and from where user language should be detected
  order: ["cookie", "header"],

  // keys or params to lookup language from
  lookupCookie: "language-etool",
  lookupHeader: "accept-language",

  // cache user language
  caches: ["cookie"],

  // optional expire and domain for set cookie
  cookieExpirationDate: new Date(),
  cookieDomain: COOKIE_DOMAIN,
  cookieSecure: true, // if need secure cookie
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    detection: options,
    resources, // resources are important to load translations for the languages.
    //lng: "en", // It acts as default language. When the site loads, content is shown in this language. Overrides laguage detection...
    fallbackLng: "pl", // use de if selected language is not available
    interpolation: {
      escapeValue: false,
    },
    ns: "translation", // namespaces help to divide huge translations into multiple small files.
    defaultNS: "translation",
    supportedLngs: ["gb", "pl"],
  });

export default i18n;
