import React from "react";
import { useTranslation } from "react-i18next";
import EntityItem from "../../prototypes/EntityItem/EntityItem";
import PropTypes from "prop-types";
import "./style.scss";

const ProjectMembersAside = ({ ownerId, projectMembers }) => {
  const { t } = useTranslation();

  if (!projectMembers) {
    return <aside className="project-members"></aside>;
  }
  return (
    <aside className="project-members">
      <h3 className="text-wrapper">{t("Team-members")}</h3>
      <div className="text-wrapper">{t("Project-leader")}:</div>
      {projectMembers &&
        projectMembers.map(({ userId, user }) =>
          userId === ownerId ? (
            <EntityItem
              key={userId}
              id={userId}
              firstName={user.firstName}
              lastName={user.lastName}
              avatarUrl={user.avatarUrl}
              skills={user.skills}
              selfPresentation={user.selfPresentation}
            />
          ) : (
            ""
          )
        )}
      {projectMembers?.length > 1 && (
        <p className="text-wrapper-3">{t("Other-people-involved")}:</p>
      )}
      <div className="person-cointainer">
        {projectMembers?.length > 1 &&
          projectMembers.map(({ userId, user }) =>
            userId !== ownerId ? (
              <EntityItem
                key={userId}
                id={userId}
                firstName={user.firstName}
                lastName={user.lastName}
                avatarUrl={user.avatarUrl}
                skills={user.skills}
                selfPresentation={user.selfPresentation}
              />
            ) : (
              ""
            )
          )}
      </div>
    </aside>
  );
};

ProjectMembersAside.propTypes = {
  ownerId: PropTypes.number,
  projectMembers: PropTypes.array,
};

export default ProjectMembersAside;
