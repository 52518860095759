import React from "react";
import { useTranslation } from "react-i18next";
import { VacatItem } from "../../prototypes/VacatItem";
import PropTypes from "prop-types";
import "./style.scss";

const ProjectVacats = ({ vacats }) => {
  const { t } = useTranslation();

  return (
    <div className="vacant-instance">
      {vacats?.length > 0 &&
        vacats.map(
          ({ id, projectId, name, description, rate, time, mode, type }) => (
            <VacatItem
              key={id}
              id={id}
              title={name}
              description={description}
              rate={rate}
              time={time}
              mode={mode}
              type={type}
              projectId={projectId}
            />
          )
        )}
      {!vacats?.length > 0 && <p>{t("List-is-empty")}...</p>}
    </div>
  );
};

ProjectVacats.propTypes = {
  vacats: PropTypes.array,
};

export default ProjectVacats;
