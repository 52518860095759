import * as React from "react";
import { Show, SimpleShowLayout, TextField, ImageField } from "react-admin";

export const AreaShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <ImageField source="avatarUrl" title="Icon" />
    </SimpleShowLayout>
  </Show>
);
