import { debug } from "./logger";

export function parseParamsToQueryString(data) {
  const flattened = Object.assign({}, data.pagination, data.sort);
  const params = new URLSearchParams(flattened);
  if (data.id) {
    data.id.forEach((id) => {
      params.append("id", id);
    });
  }
  if (data.ids) {
    data.ids.forEach((ids) => {
      params.append("ids", ids);
    });
  }
  debug(params);
  if (data.filter) {
    params.append("filter", JSON.stringify(data.filter));
  }
  debug(params.toString());
  return params.toString();
}

function extractIdsFromObject(array) {
  if (Array.isArray(array)) {
    array = array.length > 1 ? array.flatMap((row) => row) : array;
    if (array.every((item) => typeof item === "number")) {
    } else if (array.length > 0 && "id" in array[0]) {
      array = array.map((skill) => skill.id);
    }
  }

  return array;
}

export function transformIdsToSearchParams(idsArray, preifx) {
  const params = new URLSearchParams();

  idsArray = extractIdsFromObject(idsArray);

  idsArray.forEach((id) => {
    params.append(preifx, id);
  });
  return params.toString();
}
