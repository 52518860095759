import {
  ArrayField,
  BooleanField,
  ChipField,
  SingleFieldList,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const ProjectShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="description" multiline />
      <TextField source="avatarUrl" />
      <ReferenceField source="ownerId" reference="users">
        <TextField source="userName" />
      </ReferenceField>
      <ReferenceField source="areaId" reference="areas">
        <TextField source="name" />
      </ReferenceField>
      <ArrayField source="tags" reference="tags">
        <SingleFieldList>
          <ChipField source="skill.name" lin />
        </SingleFieldList>
      </ArrayField>
      <DateField source="startTime" showTime={true} />
      <DateField source="endTime" showTime={true} />
      <TextField source="cta" multiline />
      <TextField source="status" />
      <TextField source="solutionDescription" multiline />
      <TextField source="solutionHeader" multiline />
      <TextField source="prototypeFunctions" multiline />
      <TextField source="stage" />
      <BooleanField source="published" />
      <BooleanField source="public" />
      <DateField source="createdAt" showTime={true} />
      <DateField source="updatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);
