import { PASSWORD_REGEX } from "./consts";

const getRandomChar = (characters) => {
  const index =
    crypto.getRandomValues(new Uint32Array(1))[0] % characters.length;
  return characters.charAt(index);
};

const generateTempPasswordHelper = (length = 12) => {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numericChars = "0123456789";
  const specialChars = "!@#$%";

  const password = Array.from({ length }, () => {
    const category = getRandomChar("abcd");
    switch (category) {
      case "a":
        return getRandomChar(lowercaseChars);
      case "b":
        return getRandomChar(uppercaseChars);
      case "c":
        return getRandomChar(numericChars);
      case "d":
        return getRandomChar(specialChars);
      default:
        return getRandomChar(lowercaseChars);
    }
  }).join("");
  return password;
};

export const generateTempPassword = (length) => {
  let password = "";
  while (!PASSWORD_REGEX.test(password)) {
    password = generateTempPasswordHelper(length);
  }
  return password;
};
