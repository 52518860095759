import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  faLinkedin,
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer className="footer">
      <div className="logo-with-menu">
        <Link to="/">
          <img
            className="futurelabs-logo"
            alt="Futurelabs logo"
            src="/images/logo/futurelabs-logo.png"
          />
        </Link>
        {/* todo - tlumaczenia + linki */}
        <div className="right-side">
          <div className="footer-menu">
            <Link
              to="https://futurelabs.psnc.pl/"
              target="_blank"
              rel="noreferrer"
            >
              O nas
            </Link>
            <Link
              to="https://futurelabs.psnc.pl/kontakt"
              target="_blank"
              rel="noreferrer"
            >
              Kontakt
            </Link>
            <Link
              to="https://futurelabs.psnc.pl/"
              target="_blank"
              rel="noreferrer"
            >
              Aktualności
            </Link>
            <Link to="/projects">Projekt</Link>
          </div>
          <div className="footer-icons">
            <Link
              to="https://pl.linkedin.com/company/futurelabs-psnc"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon alt="Linkedin icon" icon={faLinkedin} />
            </Link>
            <Link
              to="https://www.facebook.com/PSNCFutureLabs/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon alt="Facebook icon" icon={faFacebook} />
            </Link>
            <Link
              to="https://www.youtube.com/channel/UCCyl5ntOE0gOH62XJtvDfLg"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon alt="Youtube icon" icon={faYoutube} />
            </Link>
            <Link
              to="https://www.instagram.com/psnc_future_labs/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon alt="Instagram icon" icon={faInstagram} />
            </Link>
          </div>
        </div>
      </div>
      <img
        className="separator"
        alt="Separator"
        src="/images/separator-1.svg"
      />
      <div className="bottom">
        <Link
          to="https://futurelabs.psnc.pl/"
          className="copy-text"
          target="_blank"
          rel="noreferrer"
        >
          &copy; 2023 PSNC Futurelabs. All rights reserved.
        </Link>
        <Link
          to="https://www.linkedin.com/in/wojciech-witczak-416829197"
          className="copy-text"
          target="_blank"
          rel="noreferrer"
        >
          Website developed by Wojciech Witczak
        </Link>
        <div className="privacy-links">
          <Link to="/">Privacy</Link>
          <Link to="/">Cookies</Link>
          <Link to="/">Terms</Link>
          <img
            onClick={scrollToTop}
            className="arrow-up"
            alt="Arrow up"
            src="/images/icons/arrow-up.svg"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
