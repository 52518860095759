import React from "react";
import { Link } from "react-router-dom";
const NoPrivilegesPage = () => {
  return (
    <div
      style={{
        border: "unset",
        maxWidth: "400px",
        display: "block",
        margin: "10% auto 10%",
      }}
    >
      <h1 style={{ marginBottom: "50px" }}>
        You dont have permissions to see that page...
      </h1>
      <img
        style={{
          border: "unset",
          maxWidth: "400px",
          display: "block",
          margin: "0 0 50px",
          outline: "unset",
          backgroundColor: "unset",
        }}
        src="/images/404-not-found.png"
        alt="notFound"
        name="saveForm"
        className="btTxt submit"
        id="saveForm"
      />
      <div className="flex-v-center parent-flex-column">
        <Link to="/">
          <button className="btn btn-primary">Home</button>
        </Link>
      </div>
    </div>
  );
};

export default NoPrivilegesPage;
