import { convertLegacyDataProvider } from "ra-core";
import {
  useGetListMutation,
  useGetOneMutation,
  useGetManyMutation,
  useUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
  useDeleteManyMutation,
  useGetReferenceMutation,
} from "../../../features/admin/adminApiSlice";
import {
  parseParamsToQueryString,
  transformIdsToSearchParams,
} from "../../../utils/functions";
import { debug } from "../../../utils/logger";
import { endpointErrorHandler } from "../../../utils/endpointErrorHandler";

/*
function parseParamsToQueryString(data) {
  const flattened = Object.assign({}, data.pagination, data.sort);
  if (data.target) {
    flattened.target = data.target;
  }
  if (data.id) {
    flattened.id = data.id;
  }
  if (data.ids) {
    flattened.ids = data.ids;
  }
  if (data.filter) {
    const filterParams = new URLSearchParams(data.filter);
    filterParams.forEach((value, key) => {
      flattened[key] = value;
    });
  }
  debug(flattened);
  return new URLSearchParams(flattened).toString();
}*/

const CustomReduxDataProvider = (apiSlice) => {
  const [getList] = useGetListMutation();
  const [getMany] = useGetManyMutation();
  const [getOne] = useGetOneMutation();
  const [getReference] = useGetReferenceMutation();
  const [update] = useUpdateMutation();
  const [create] = useCreateMutation();
  const [remove] = useDeleteMutation();
  const [removeMany] = useDeleteManyMutation();
  const dataProvider = async (type, resource, params) => {
    debug(
      "CustomReduxDataProvider",
      "type:",
      type,
      "resource:",
      resource,
      "params:",
      params
    );
    try {
      switch (type) {
        case "GET_LIST":
          const listResponse = await getList({
            resource,
            params: parseParamsToQueryString(params),
          }).unwrap();
          return listResponse;

        case "GET_ONE":
          const singleResponse = await getOne({
            resource,
            id: params.id,
          }).unwrap();
          return singleResponse;

        case "GET_MANY":
          const manyResponse = await getMany({
            resource,
            params: transformIdsToSearchParams(params.ids, "id"),
          }).unwrap();
          return manyResponse;

        case "GET_MANY_REFERENCE":
          const referenceResponse = await getReference({
            resource,
            params: parseParamsToQueryString(params),
          }).unwrap();
          return referenceResponse;

        case "UPDATE":
          await update({
            resource,
            id: params.id,
            data: params.data,
          }).unwrap();
          return { data: params.data };

        case "CREATE":
          const createResponse = await create({
            resource,
            data: params.data,
          }).unwrap();
          return { data: createResponse.data };

        case "DELETE":
          await remove({ resource, id: params.id }).unwrap();
          return { data: { id: params.id } };

        case "DELETE_MANY":
          await removeMany({
            resource,
            params: transformIdsToSearchParams(params.ids, "id"),
          }).unwrap();
          return { data: params.ids };

        default:
          throw new Error(`Unsupported Data Provider request type ${type}`);
      }
    } catch (err) {
      endpointErrorHandler(err);
      throw err;
    }
  };

  return convertLegacyDataProvider(dataProvider);
};

export default CustomReduxDataProvider;
