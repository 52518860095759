import {
  Datagrid,
  EmailField,
  List,
  TextField,
  TextInput,
} from "react-admin";

const postFilters = [
  <TextInput label="Username" source="userName" alwaysOn />,
  <TextInput label="Email" source="email" alwaysOn />,
  <TextInput label="Phone" source="phone" alwaysOn />,
];

export const UserList = (props) => (
  <List {...props} filters={postFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="userName" />
      <EmailField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phone" />
      <TextField source="projectCount" />
    </Datagrid>
  </List>
);
