import React from "react";
import HomePageCommunityBox from "../sections/HomePageCommunityBox/HomePageCommunityBox";

const HomePage = () => {
  return (
    <div className="box parent-flex-column">
      <HomePageCommunityBox />
    </div>
  );
};

export default HomePage;
