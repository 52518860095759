import React from "react";
import { Link } from "react-router-dom";
const ContactListPage = () => {
  return (
    <div className="ptba4 plra2 parent-flex-column">
      <h1>Lista kontaktów - to do</h1>
    </div>
  );
};

export default ContactListPage;
