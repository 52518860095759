import { useLocation, Navigate, Outlet } from "react-router-dom";
import { selectCurrentStatuses, selectCurrentUser } from "./authSlice";
import Loading from "../../components/prototypes/Loading";
import { useSelector } from "react-redux";
import { debug } from "../../utils/logger";

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const user = useSelector(selectCurrentUser);
  const { isLoading, isError, isSuccess, isWaiting } = useSelector(
    selectCurrentStatuses
  );

  debug(
    "RequireAuth",
    "isLoading",
    isLoading,
    "isError",
    isError,
    "isSuccess",
    isSuccess,
    "user",
    user,
    "allowedRoles",
    allowedRoles
  );

  if (!isLoading && isError) {
    return <Navigate to="/no-privileges" state={{ from: location }} replace />;
  } else if (
    (isLoading && !isSuccess) ||
    (!isLoading && !isSuccess && !isError && isWaiting)
  ) {
    return (
      <div className="ptba4">
        <Loading />
      </div>
    );
  }

  return user?.groups?.find((group) =>
    allowedRoles?.some(
      (allowedRole) => group.group?.permissionLevel >= allowedRole
    )
  ) ? (
    <Outlet />
  ) : (
    <Navigate to="/no-privileges" state={{ from: location }} replace />
  );
};

export default RequireAuth;
