import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    permissionsLevel: 0,
    token: localStorage.getItem("accessToken") || null,
    status: {
      isLoading: false,
      isSuccess: false,
      isError: false,
      isWaiting: true,
    },
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      state.user = user;
      state.token = accessToken;
      localStorage.setItem("accessToken", accessToken);

      if (user?.groups.length > 0) {
        state.permissionsLevel = Math.max(
          ...user?.groups.map((item) => item.group.permissionLevel)
        );
      } else state.permissionsLevel = 0;
    },
    setLogout: (state, action) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("accessToken");
    },
    setStatus: (state, action) => {
      const payload = action.payload;
      state.status = { ...payload };
    },
  },
});

export const { setCredentials, setLogout, setStatus } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentStatuses = (state) => state.auth.status;
export const selectCurrentUserPermissionsLevel = (state) =>
  state.auth.permissionsLevel;
