import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  faRightToBracket,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import { validateRegistration } from "../../utils/validators";

const RegisterPage = () => {
  const { t } = useTranslation();
  const userRef = useRef();
  const { handleRegister } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userRef.current) userRef.current.focus();
  }, []);

  const intialValues = {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confPassword: "",
  };
  let [isWaiting, setWaiting] = useState(false);
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setFormErrors({});
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.botField.value) {
      // Possibly a bot, reject submission
      alert("Bot detected. Please try again.");
      return;
    }
    setFormErrors(validateRegistration(formValues));
    setIsSubmitting(true);
  };

  const submitForm = async () => {
    try {
      setWaiting(true);
      await handleRegister(
        formValues.firstName,
        formValues.lastName,
        formValues.username,
        formValues.email,
        formValues.password
      );
      setFormValues(intialValues);
      navigate("/confirm-email", { replace: true });
    } catch (err) {
      setFormErrors({ server: err.status });
    }
    setWaiting(false);
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }
    setIsSubmitting(false);
  }, [formErrors]);

  return (
    <div
      className="gradient-container parent-flex-column"
      style={{ backgroundImage: "url(/images/gradient-background.png)" }}
    >
      <div className="def-div w500px">
        <h1 className="ta-c">{t("Register")}</h1>
        {Object.keys(formErrors).length === 0 && isSubmitting && (
          <span className="success-msg">Signed in successfully</span>
        )}
        <form onSubmit={handleSubmit} noValidate>
          <div
            className="form-row flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <div style={{ flex: "0 1 49%" }}>
              <label htmlFor="firstName">{t("First-name")}</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                ref={userRef}
                placeholder={t("First-name")}
                value={formValues.firstName}
                onChange={handleChange}
                className={
                  (formErrors.server || formErrors.firstName) && "error"
                }
                required
              />
              {formErrors.firstName && (
                <p className={"error instructions"}>
                  <FontAwesomeIcon icon={faInfoCircle} />{" "}
                  {t(formErrors.firstName?.key, formErrors.firstName?.keys)}
                </p>
              )}
            </div>

            <div style={{ flex: "0 1 49%" }}>
              <label htmlFor="LastName">{t("Last-name")}</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                placeholder={t("Last-name")}
                value={formValues.lastName}
                onChange={handleChange}
                className={
                  (formErrors.server || formErrors.lastName) && "error"
                }
                required
              />
              {formErrors.lastName && (
                <p
                  className={
                    formErrors.lastName ? "error instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />{" "}
                  {t(formErrors.lastName?.key, formErrors.lastName?.keys)}
                </p>
              )}
            </div>
          </div>
          <div className="form-row">
            <label htmlFor="email">{t("Email")}</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder={t("Email")}
              value={formValues.email}
              onChange={handleChange}
              className={(formErrors.server || formErrors.email) && "error"}
              required
            />
            {formErrors.email && (
              <p
                className={
                  formErrors.email ? "error instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} /> {t(formErrors.email)}
              </p>
            )}
          </div>
          <div className="form-row">
            <label htmlFor="username">{t("Username")}</label>
            <input
              type="text"
              name="username"
              id="username"
              autoComplete="off"
              placeholder={t("Username")}
              value={formValues.username}
              onChange={handleChange}
              className={(formErrors.server || formErrors.username) && "error"}
              required
            />
            {formErrors.username && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t(formErrors.username?.key, formErrors.username?.keys)}
              </p>
            )}
          </div>
          <div className="form-row">
            <label htmlFor="password">{t("Password")}</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder={t("Password")}
              value={formValues.password}
              onChange={handleChange}
              className={(formErrors.server || formErrors.password) && "error"}
              required
            />
            {formErrors.password && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t("Field-must-be-more-than-and-less-than-characters", {
                  min: 8,
                  max: 24,
                })}
                .
                <br />
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t("Must-include-password-text")}.
                <br />
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t("Allowed-special-characters")}:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </p>
            )}
          </div>
          <div className="form-row">
            <label htmlFor="confPassword">
              {t("Confirm") + " " + t("Password")}
            </label>
            <input
              type="password"
              name="confPassword"
              id="confPassword"
              placeholder={t("Confirm") + " " + t("Password")}
              value={formValues.confPassword}
              onChange={handleChange}
              className={
                (formErrors.server || formErrors.confPassword) && "error"
              }
              required
            />
            {formErrors.confPassword && (
              <p className={"error instructions"}>
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {t(formErrors.confPassword)}
              </p>
            )}
          </div>
          <div className="form-row">
            <input
              type="text"
              name="botField"
              style={{ display: "none" }}
              tabIndex="-1"
              autoComplete="off"
            />
          </div>
          <div className="flex-end">
            <Link
              to={"/login"}
              className="go-back-link"
              style={{ marginRight: "20px" }}
            >
              <FontAwesomeIcon icon={faRightToBracket} />
              {t("Already-have-account")}?
            </Link>
            <button
              type="submit"
              disabled={isWaiting === true ? true : false}
              className="btn btn-primary"
            >
              {t("Register")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default RegisterPage;
