import { Datagrid, List, TextField, NumberField } from "react-admin";

export const CompanyList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="type" />
    </Datagrid>
  </List>
);
