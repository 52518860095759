import React from "react";
import { useTranslation } from "react-i18next";

import "./style.scss";

const UserPage = () => {
  const { t } = useTranslation();

  return (
    <div className="ptba4 plra2 user-page w1000px parent-flex-column">
      UserPage
    </div>
  );
};

export default UserPage;
