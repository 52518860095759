import React from "react";
import { useConfirmEmailQuery } from "../../features/auth/authApiSlice";
import Loading from "../prototypes/Loading";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EmailConfirmLandingPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { isLoading, isSuccess, isError, error } = useConfirmEmailQuery(token);

  return (
    <div
      className="gradient-container parent-flex-column"
      style={{ backgroundImage: "url(/images/gradient-background.png)" }}
    >
      {isLoading && <Loading />}
      {isSuccess && !isError && (
        <div className="frame">
          <div className="top">
            <div className="text-wrapper">Weryfikacja adresu e-mail</div>
            <div className="info">
              <p>Weryfikacja przebiegła pomyślnie</p>
            </div>
            <Link to="/login">
              <button className="btn btn-primary">{t("Login")}</button>
            </Link>
          </div>
        </div>
      )}
      {!isLoading && isError && (
        <div className="frame">
          <div className="top">
            <div className="text-wrapper">Weryfikacja adresu e-mail</div>
            <div className="info">
              <p>Link aktywacyjny nie jest już aktywny.</p>
            </div>
            <Link to="/">
              <button className="btn btn-primary">{t("Home")}</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailConfirmLandingPage;
