import {
  ReferenceArrayInput,
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  ReferenceManyField,
  SelectArrayInput,
  TextField,
  Datagrid,
  ReferenceInput,
  SingleFieldList,
  SelectInput,
  ChipField,
  ReferenceArrayField,
  useEditController,
  useRefresh,
  required,
} from "react-admin";
import {
  useGetImageUrlMutation,
  useUploadResourceAvatarMutation,
} from "../../../../features/file/fileApiSlice";
import AvatarUpload from "../../../prototypes/AvatarUpload/AvatarUpload";

export const UserEdit = (props) => {
  const refresh = useRefresh();
  const [getAvatar] = useGetImageUrlMutation();
  const [saveAvatar] = useUploadResourceAvatarMutation();
  const { record, save } = useEditController(props);

  return (
    <Edit>
      <SimpleForm>
        <AvatarUpload
          imageName={record?.avatarUrl}
          id={record?.id}
          refetch={refresh}
          uploadAvatar={saveAvatar}
          getAvatar={getAvatar}
          resource={"admin/image/users"}
          style={{ marginTop: "10px", marginLeft: "10px" }}
        />
        <TextInput source="avatarUrl" disabled />
        <TextInput source="userName" validate={[required()]} />
        <TextInput source="email" validate={[required()]} />
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <TextInput source="phone" />

        <ReferenceArrayInput reference="groups" source="groups">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <ReferenceInput source="companyId" reference="companies">
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceArrayInput reference="skills" source="skills">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <ReferenceArrayInput reference="interests" source="interests">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <ReferenceArrayInput reference="projects" source="projectsMember">
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>

        <div style={{ padding: "10px 0" }}>
          <h6 style={{ paddingBottom: "10px" }}>Project Ownership</h6>
          <ReferenceArrayField
            label="Project Ownership"
            reference="projects"
            source="projectOwnership"
          >
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
            {/*<SelectArrayInput optionText="title" />*/}
          </ReferenceArrayField>
        </div>

        {/*
        <ReferenceArrayInput reference="vacats" source="vacatApplies">
          <SelectArrayInput optionText="id" />
        </ReferenceArrayInput>
         */}
        <div style={{ padding: "10px 0" }}>
          <h6 style={{ paddingBottom: "10px" }}>Vacat Applies</h6>
          <ReferenceArrayField
            label="Vacat Applies"
            reference="vacats"
            source="vacatApplies"
          >
            <SingleFieldList>
              <ChipField source="id" />
            </SingleFieldList>
          </ReferenceArrayField>
        </div>

        {/* 
        <ReferenceArrayInput
          reference="stakeholders"
          source="stakeholderOwnership"
        >
          <SelectArrayInput optionText="id" />
        </ReferenceArrayInput>
        */}
        <div style={{ padding: "10px 0" }}>
          <h6 style={{ paddingBottom: "10px" }}>Stakeholder Ownership</h6>
          <ReferenceArrayField
            label="Stakeholder Ownership"
            reference="stakeholders"
            source="stakeholderOwnership"
          >
            <SingleFieldList>
              <ChipField source="id" />
            </SingleFieldList>
          </ReferenceArrayField>
        </div>
      </SimpleForm>
    </Edit>
  );
};
