import React, { useRef, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  faUpload,
  faFile,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { errorNotification } from "../../../utils/popNotifications";
import "./style.scss";
import ImageWithCallback from "../ImageWithCallback";

const AvatarUpload = ({
  imageName,
  id,
  refetch,
  uploadAvatar,
  getAvatar,
  resource,
  style,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");

  const handleFileChange = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };
  const onChooseFile = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };

  const clearFileInput = () => {
    inputRef.current.value = "";
    setSelectedFile(null);
    setProgress(0);
    setUploadStatus("select");
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!id) return;
    if (uploadStatus === "done") {
      clearFileInput();
      refetch();
      return;
    }
    try {
      setUploadStatus("uploading");
      const formData = new FormData();
      formData.append("image", selectedFile);
      const response = await uploadAvatar({ formData, resource, id });

      if (response?.error) {
        throw new Error(
          `${response.error?.data?.statusCode}: ${response.error?.data?.message}`
        );
      }
      setProgress(100);
      setUploadStatus("done");
    } catch (error) {
      errorNotification("Error", error.message);
      setUploadStatus("select");
    }
  };

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  return (
    <div className="avatar-upload-container flex-row" style={style}>
      <input
        type="file"
        name="avatar"
        id="avatar"
        accept="image/png, image/gif, image/jpeg"
        autoComplete="off"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {!selectedFile && (
        <div>
          <div className="preview-card">
            <ImageWithCallback
              imageName={imageName}
              alt={t("Avatar")}
              getAvatar={getAvatar}
              resource={resource}
            />
          </div>
        </div>
      )}
      {!selectedFile && (
        <button className="file-btn" onClick={onChooseFile}>
          <span className="material-symbols-outlined">
            <FontAwesomeIcon icon={faUpload} size="xs" />
          </span>
          {t("Update")} {t("Avatar")}
        </button>
      )}

      {selectedFile && (
        <>
          <div>
            <label>
              {t("Your")} {t("Avatar")}
            </label>
            <div className="preview-card">
              <img src={preview} alt={t("Avatar")} />
            </div>
          </div>
          <div>
            <div className="file-card">
              <span className="material-symbols-outlined icon">
                <FontAwesomeIcon icon={faFile} size="xs" />
              </span>

              <div className="file-info">
                <div style={{ flex: 1 }}>
                  <h6>{selectedFile?.name}</h6>

                  <div className="progress-bg">
                    <div
                      className="progress"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>

                {uploadStatus === "select" ? (
                  <button onClick={clearFileInput}>
                    <span className="material-symbols-outlined close-icon">
                      <FontAwesomeIcon icon={faTimes} size="lg" />
                    </span>
                  </button>
                ) : (
                  <div className="check-circle">
                    {uploadStatus === "uploading" ? (
                      `${progress}%`
                    ) : uploadStatus === "done" ? (
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: "20px" }}
                      >
                        <FontAwesomeIcon icon={faCheck} size="lg" />
                      </span>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <button className="upload-btn" onClick={handleUpload}>
              {uploadStatus === "select" || uploadStatus === "uploading"
                ? t("Upload")
                : t("Done")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AvatarUpload;
