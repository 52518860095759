import {
  Datagrid,
  List,
  TextField,
  NumberField,
  EmailField,
  ReferenceField,
  TextInput,
  SelectInput,
  NullableBooleanInput,
  TopToolbar,
  SelectColumnsButton,
  SortButton,
  CreateButton,
  ExportButton,
} from "react-admin";
import {
  satisfiedChoices,
  engagedChoices,
  monitoredChoices,
  informedChoices,
} from "../../../../utils/consts";

const postFilters = [
  <TextInput label="Email" source="email" alwaysOn />,
  <TextInput label="First Name" source="firstName" alwaysOn />,
  <TextInput label="Lirst Name" source="lastName" alwaysOn />,
  <SelectInput
    label="Satisfied"
    source="satisfied"
    alwaysOn
    emptyText="Unset"
    choices={satisfiedChoices}
  />,
  <SelectInput
    label="Engadged"
    source="engaged"
    emptyText="Unset"
    alwaysOn
    choices={engagedChoices}
  />,
  <SelectInput
    label="Monitored"
    source="monitored"
    alwaysOn
    emptyText="Unset"
    choices={monitoredChoices}
  />,
  <SelectInput
    label="Informed"
    source="informed"
    emptyText="Unset"
    alwaysOn
    choices={informedChoices}
  />,
];

const ListActions = (props) => (
  <TopToolbar>
    <SelectColumnsButton />
    <SortButton
      fields={[
        "id",
        "firstName",
        "lastName",
        "satisfied",
        "engaged",
        "monitored",
        "informed",
      ]}
    />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const StakeholderList = (props) => (
  <List {...props} filters={postFilters} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phone" />
      <ReferenceField source="ownerId" reference="users">
        <TextField source="userName" />
      </ReferenceField>
      <ReferenceField source="areaId" reference="areas">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="companyId" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="links" />
      <TextField source="description" />
      <NumberField source="satisfied" />
      <NumberField source="engaged" />
      <NumberField source="monitored" />
      <NumberField source="informed" />
    </Datagrid>
  </List>
);
