import { apiSlice } from "../../app/api/apiSlice";

export const companyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getManyCompanies: builder.query({
      query: () => ({
        url: "/company/many",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetManyCompaniesQuery } = companyApiSlice;
